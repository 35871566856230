import { Component, Inject, OnInit } from '@angular/core';
import { MatBottomSheetRef, MAT_BOTTOM_SHEET_DATA } from '@angular/material/bottom-sheet';
import { Order, Product, PRODUCT_STATUS } from 'projects/fe-common-v2/src/lib/models/product-tracking/products-tracking';

export enum UIMode {
    Tracking,
    QA,
    Returned,
    Actions,
    SLActions,
    PackageInspect
}

@Component({
    selector: 'app-product-tracking-action-sheet',
    templateUrl: './product-tracking-action-sheet.component.html',
    styleUrls: ['./product-tracking-action-sheet.component.scss']
})
export class ProductTrackingActionSheetComponent implements OnInit {
    ProductStatus = PRODUCT_STATUS;
    UIMode = UIMode;

    currentPlan: any = {};
    currentProduct: any = {};
    currentProductWorkingData: any = {};
    currentMode: UIMode;
    doneEvent: Function;
    changeStateEvent: Function;
    productActions: any[] = [];
    productDefects: any[] = [];
    productInfos: any[] = [];
    showNote: boolean = false;
    showDefect: boolean = false;
    note: string = '';
    defect: string = '';
    SLproductQR: string = '';
    packageInfo: any = {};
    packageQR: string = '';
    packageContents: any[] = [];
    itemSL: any = {};
    
    showTechSpec: boolean = false;
    showAssemplySpec: boolean = false;

    constructor(private bottomSheetRef: MatBottomSheetRef<ProductTrackingActionSheetComponent>,
        @Inject(MAT_BOTTOM_SHEET_DATA) public data: any) {

        this.currentMode = data.mode;
        this.currentPlan = data.planData;
        this.currentProduct = data.productItem;
        this.currentProductWorkingData = data.productWorkingData;
        this.doneEvent = data.doneEvent;
        this.changeStateEvent = data.changeStateEvent;
        this.productActions = data.productActions;
        this.productDefects = data.productDefects;
        this.productInfos = data.productInfos;
        this.showDefect = data.showDefect;
        this.showNote = data.showNote;
        this.SLproductQR = data.qr;
        this.packageInfo = data.packageType;
        this.packageQR = data.packageQR;
        this.packageContents = data.packageContents;
        this.itemSL = data.itemSL;

        try {
            this.showTechSpec = this.currentProductWorkingData.productInfo && this.currentProductWorkingData.productInfo.techSpecFileId && this.currentProductWorkingData.productInfo.techSpecFileName;
            this.showAssemplySpec = this.currentProductWorkingData.productInfo && this.currentProductWorkingData.productInfo.assemplySpecFileId && this.currentProductWorkingData.productInfo.assemplySpecFileName;
        } catch (ex) {

        }
    }

    ngOnInit(): void {
    }

    getActionStyle(isexit: boolean = false) {
        return {
            'background': isexit ? '#ff0000' : '#0640A9',
            'color': '#0640A9'
        }
    }

    async onProductChangeStatus(productStatus: PRODUCT_STATUS) {
        await this.doneEvent(this.currentProduct.activationId, productStatus);
        this.bottomSheetRef.dismiss();
    }

    async onProductAction(event) {
        await this.changeStateEvent(this.currentProduct.activationId, event.action, this.note, this.defect);
        this.bottomSheetRef.dismiss();
    }

    async onSLProductAction(event) {
        await this.changeStateEvent(this.currentProduct, event.action, this.SLproductQR, this.note, this.defect);
        this.bottomSheetRef.dismiss();
    }

    onCloseDrawer() {
        this.bottomSheetRef.dismiss();
    }

    onScrapProduct() {
        this.bottomSheetRef.dismiss();
    }

    onOpenDocument(docId) {

    }
}
