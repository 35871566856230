<mat-nav-list class="navigation-list">
    <div class="navigation-item info-tile info-background item-text info-text">
        <mat-label>
            Help Desk Irina attivo dalle 9:00 alle 18:00.<br>
            Presa in carico della segnalazione entro massimo 4 ore.<br>
            email : irina@nunow.net<br>
            Tel: +39 03311940062<br>
            Contattare via telefono solo in caso di urgenze.
        </mat-label>
    </div>
    <a *ngFor="let hd of helpDeskAction" mat-list-item class="navigation-item"
        [ngStyle]="getActionStyle(hd)" (click)="onServiceAction(hd)">
        <mat-label class="item-text white-text" mat-line>{{ hd.Caption }}</mat-label>
        <i class="material-icons white-text">{{ hd.Icon }}</i>
    </a>
</mat-nav-list>