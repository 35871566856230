<div class="page-container">
    <app-main-toolbar class="main-toolbar"></app-main-toolbar>
    <div class="page-content-container">
        <div class="control-container">
            <div *ngIf="currentUIMode == UI_MODE.uiModeStart" class="main-action-container">
                <mat-nav-list class="action-list">
                    <ng-container>
                        <a mat-list-item class="action-item" (click)="onSelectMode(UI_MODE.uiModeContainer)">
                            <div class="activate-button">
                                <mat-label class="item-text white-text" mat-line>Movimenta Container</mat-label>
                            </div>
                            <i class="material-icons white-text button-icon">view_in_ar</i>
                        </a>

                        <a mat-list-item class="action-item" (click)="onSelectMode(UI_MODE.uiModeProducts)">
                            <div class="activate-button">
                                <mat-label class="item-text white-text" mat-line>Movimenta Prodotti</mat-label>
                            </div>
                            <i class="material-icons white-text button-icon">category</i>
                        </a>

                    </ng-container>
                </mat-nav-list>
            </div>

            <div *ngIf="currentUIMode == UI_MODE.uiModeContainer" class="qr-scanner-container">
                <div class="qr-scanner-label color blue-back">
                    <div class="qr-scanner-text white-text">Inquadra il QR del container e seleziona l'area di destinazione</div>
                    <mat-form-field class="area-selector">
                        <mat-select [(ngModel)]="destinationArea">
                            <mat-option *ngFor="let a of areas" [value]="a.name">
                                {{a.name}}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
    
                </div>
                <fco-qr-scanner #scanner (scanSuccess)="onScanContainer($event)" [camera]="currentCamera">
                </fco-qr-scanner>
            </div>

            <div *ngIf="currentUIMode == UI_MODE.uiModeProducts" class="main-action-container">
                <mat-nav-list class="action-list">
                    <a *ngFor="let item of itemsToMoveInProduction" mat-list-item class="action-item" (click)="onItemMoveComplete(item)">
                        <div class="activate-button">
                            <div class="details">
                                <mat-label class="item-text white-text" mat-line>Prodotto: {{ item.codeSL }}</mat-label>
                                <mat-label class="item-text white-text" mat-line>Operatore: {{ item.operatorName }}</mat-label>
                            </div>
                            <span class="spacer"></span>
                            <div class="item-qty">
                                <mat-label class="item-text white-text" mat-line>{{ getMovedText(item) }}</mat-label>
                            </div>
                        </div>
                        <i class="material-icons white-text button-icon">fast_forward</i>
                    </a>
                </mat-nav-list>
            </div>
        </div>
    </div>
    <app-main-tab-bar class="main-tab-bar" [ShowBack]="true" (BackEvent)="onBack()"></app-main-tab-bar>
</div>