import { Component, OnInit, ViewChild } from '@angular/core';
import { MatBottomSheet } from '@angular/material/bottom-sheet';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { ProductsTrackingService } from 'projects/fe-common-v2/src/lib/services/products-tracking.service';
import { QrScannerComponent, CameraType } from 'projects/fe-common/src/lib/components/qr-scanner/qr-scanner.component';
import { AdminSiteManagementService } from 'projects/fe-common/src/lib/services/admin-site-management.service';
import { CommonService } from 'projects/fe-common/src/lib/services/common.service';
import { NotifyManagementService } from 'projects/fe-common/src/lib/services/notify-management.service';
import { SettingsManagementService } from 'projects/fe-common/src/lib/services/settings-management.service';
import { UserManagementService } from 'projects/fe-common/src/lib/services/user-management.service';
import { ProductTrackingActionSheetComponent, UIMode } from '../product-tracking-action-sheet/product-tracking-action-sheet.component';

enum UI_MODE {
    uiModeStart,
    uiInitPackage,
    uiMoveIntoPackage,
    uiMoveOutsidePackage,
    uiInspectPackage
}

enum SCAN_STATE {
    ssPackage,
    ssProduct
}

enum PACKAGE_ACTION {
    paAdd = 'ADD',
    paRemove = 'REMOVE',
    paInspect = 'INSPECT'
}

@Component({
    selector: 'app-product-packaging',
    templateUrl: './product-packaging.component.html',
    styleUrls: ['./product-packaging.component.scss']
})
export class ProductPackagingComponent implements OnInit {
    @ViewChild('scanner') qrScanner: QrScannerComponent;

    scanning: boolean = false;
    currentCamera: CameraType = CameraType.Back;

    UI_MODE = UI_MODE;
    SCAN_STATE = SCAN_STATE;

    currentUIMode: UI_MODE = UI_MODE.uiModeStart;
    currentScanState: SCAN_STATE = SCAN_STATE.ssPackage;
    currentPackageAction: PACKAGE_ACTION = PACKAGE_ACTION.paAdd;

    packageTypes: any[] = [];
    packageTypeId: any;

    currentPackageInstanceQR: string = null;

    constructor(private commonService: CommonService,
        private adminSiteManagementService: AdminSiteManagementService,
        private productsTrackingService: ProductsTrackingService,
        private userManagementService: UserManagementService,
        private bottomSheet: MatBottomSheet,
        private settingsManagementService: SettingsManagementService,
        private notifyManagementService: NotifyManagementService,
        private snackBar: MatSnackBar,
        private dialog: MatDialog,
        public translate: TranslateService,
        private router: Router) { }

    ngOnInit(): void {
    }

    getActionStyle(isexit: boolean = false) {
        return {
            'background': isexit ? '#ff0000' : '#0640A9',
            'color': '#0640A9'
        }
    }

    sleep(ms) {
        return new Promise(resolve => setTimeout(resolve, ms));
    }
    
    async getPackageTypes() {
        let res = await this.productsTrackingService.getPackageTypes(null);
        if (this.commonService.isValidResponse(res)) {
            return res.meta.data;
        } else {
            await this.notifyManagementService.openMessageDialog('ATTENZIONE', 'Si è verificato un errore: ' + res.reason);
            return [];
        }
    }

    async onSelectMode(mode) {
        console.log('scanmode:', mode);

        if (mode == UI_MODE.uiInitPackage) {
            this.packageTypes = await this.getPackageTypes();
            if (this.packageTypes && (this.packageTypes.length > 0))
                this.packageTypeId = this.packageTypes[0].id;
        } else if (mode == UI_MODE.uiMoveIntoPackage) {
            this.currentScanState = SCAN_STATE.ssPackage;
            this.currentPackageAction = PACKAGE_ACTION.paAdd;
        } else if (mode == UI_MODE.uiMoveOutsidePackage) {
            this.currentScanState = SCAN_STATE.ssProduct;
            this.currentPackageAction = PACKAGE_ACTION.paRemove;
        } else if (mode == UI_MODE.uiInspectPackage) {
            this.currentScanState = SCAN_STATE.ssPackage;
            this.currentPackageAction = PACKAGE_ACTION.paInspect;
        }

        this.currentUIMode = mode;
    }

    async onScan(resultString) {
        if ((resultString == null) || this.scanning)
            return;

        this.scanning = true;
        this.qrScanner.openUserMedia();

        if (this.currentUIMode == UI_MODE.uiInspectPackage) {
            let res = await this.productsTrackingService.inspectPackageInstance(resultString);
            if (this.commonService.isValidResponse(res)) {
                console.log('inspectPackageInstance', res); 
                this.bottomSheet.open(ProductTrackingActionSheetComponent, {
                    data: {
                        mode: UIMode.PackageInspect,
                        packageType: res.data.packageType,
                        packageQR: res.data.packageQR,
                        packageContents: res.data.contents
                    },
                    panelClass: 'bottom-sheet'
                });
            } else {
                await this.notifyManagementService.openMessageDialog('ATTENZIONE', 'Si è verificato un errore: ' + res.reason);
                this.currentUIMode = UI_MODE.uiModeStart;
            }
        } else {
            // verificare il QR code del container
            let res = await this.productsTrackingService.validateAndCreatePackageQR(resultString, this.packageTypeId);
            if (this.commonService.isValidResponse(res)) {
                await this.notifyManagementService.openMessageDialog('INFO', 'L\'imballo è stato attivato');
                this.currentUIMode = UI_MODE.uiModeStart;
            } else {
                await this.notifyManagementService.openMessageDialog('ATTENZIONE', 'Si è verificato un errore: ' + res.reason);
                this.currentUIMode = UI_MODE.uiModeStart;
            }
        }

        this.scanning = false;
    }

    async onScanPackage(resultString) {
        if ((resultString == null) || this.scanning)
            return;

        this.scanning = true;
        this.qrScanner.openUserMedia();

        this.currentPackageInstanceQR = resultString;
        if (!(this.currentPackageAction == PACKAGE_ACTION.paInspect)) {
            this.currentScanState = SCAN_STATE.ssProduct;
        }

        // await this.notifyManagementService.openMessageDialog('INFO', 'L\'imballo è stato riconosciuto. Ora puoi preparare il pezzo da inserire.');
        await this.sleep(5000);

        this.scanning = false;
    }

    async onScanProduct(resultString) {
        if ((resultString == null) || this.scanning)
            return;

        this.scanning = true;
        this.qrScanner.openUserMedia();

        let res = await this.productsTrackingService.itemActionInPackageInstance(this.currentPackageAction, this.currentPackageInstanceQR, resultString);
        if (this.commonService.isValidResponse(res)) {
            await this.notifyManagementService.openMessageDialog('INFO', this.currentPackageAction == PACKAGE_ACTION.paAdd ? 'Il prodotto è stato inserito nell\'imballo' : 'Il prodotto è stato rimosso dall\'imballo');

            this.currentUIMode = UI_MODE.uiModeStart;
            this.currentPackageInstanceQR = null;
        } else {
            await this.notifyManagementService.openMessageDialog('ATTENZIONE', 'Si è verificato un errore: ' + res.reason);
            this.currentUIMode = UI_MODE.uiModeStart;

        }

        this.scanning = false;
    }

    async onBack() {
        this.router.navigate(["home"]);
    }
}
