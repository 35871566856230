import { Injectable } from '@angular/core';
import { ITEM_STATUS, Order, Plan, Product, PRODUCT_STATUS } from '../models/product-tracking/products-tracking';
import { ApiService, buildRequest } from './api';
import { BaseResponse } from './base-response';
import { HttpClient, HttpRequest } from '@angular/common/http';

export enum DEFECT_SCOPE {
    dsProduction = 'scope-production',          // difetti di produzione (SL)
    dsWork = 'scope-work'                       // difetti lavorazione (PF)
}

export enum FINAL_PROD_TYPE {
    FLAT = 'flat',
    AGGREGATE = 'aggregate'
}

export enum CUSTOMER_ORDER_GROUP {
    DOC = 'by_document',
    ITEM = 'by_item',
    ITEM_PLAN = 'by_item_plan',
    CUSTOMER = 'by_customer',
    DEPARTMENT = 'by_department'
}

export class OrderListResult extends BaseResponse {
    orders: Order[];
}

export class OrderResult extends BaseResponse {
    order: Plan;
}

export class ProductResult extends BaseResponse {
    product: Product;
}

export class OrderProductsResult extends BaseResponse {
    products: Product[];
}

export class OrderProductResult extends BaseResponse {
    product: Product;
    order: Order;
}

export class DashboardResult extends BaseResponse {
    productCount: number;
    orderCount: number;
}

export class MetaResult extends BaseResponse {
    meta: any;
    data: any;
}

@Injectable({
    providedIn: 'root'
})
export class ProductsTrackingService {

    constructor(private apiService: ApiService,
        private http: HttpClient) { }

    // SYNC SQL
    //
    syncSQLDatabase(): Promise<MetaResult> {
        return this.apiService.get<MetaResult>(this.apiService.API.BE.SQL_SYNC).toPromise();
    }

    // COMPONENTI
    getItemsComponents(queryParams): Promise<MetaResult> {
        return this.apiService.post<MetaResult>(this.apiService.API.BE.LIST_ITEMS_COMPONENTS, queryParams).toPromise();
    }

    syncItemsComponents(): Promise<BaseResponse> {
        return this.apiService.get<BaseResponse>(this.apiService.API.BE.SYNC_ITEMS_COMPONENTS).toPromise();
    }

    // ARTICOLI
    //
    getItems(queryParams): Promise<MetaResult> {
        return this.apiService.post<MetaResult>(this.apiService.API.BE.LIST_ITEMS, queryParams).toPromise();
    }

    syncItems(): Promise<BaseResponse> {
        return this.apiService.get<BaseResponse>(this.apiService.API.BE.SYNC_ITEMS).toPromise();
    }

    getItemByCode(code): Promise<MetaResult> {
        let url = buildRequest(this.apiService.API.BE.ITEM_CODE,
            {
                ':code': code
            });

        return this.apiService.get<MetaResult>(url).toPromise();
    }

    getItemByCodeEx(code): Promise<MetaResult> {
        return this.apiService.post<MetaResult>(this.apiService.API.BE.ITEM_CODE_EX, { code: code }).toPromise();
    }

    importItemWorkTimeFromFile(file: File): Promise<any> {
        let formData: FormData = new FormData();
        formData.append("file", file, file.name);

        let req = new HttpRequest("POST", this.apiService.resolveApiUrl(this.apiService.API.BE.ITEM_WORK_TIME_IMPORT), formData, {
            reportProgress: true
        });

        return this.http.request(req).toPromise();
    }


    createOrUpdateItem(itemData): Promise<BaseResponse> {
        return this.apiService.post<BaseResponse>(this.apiService.API.BE.ITEM_ENTRY, itemData).toPromise();
    }

    // ORDINI CLIENTI
    //
    getCustomerOrders(queryParams): Promise<MetaResult> {
        return this.apiService.post<MetaResult>(this.apiService.API.BE.LIST_CUSTOMER_ORDERS, queryParams).toPromise();
    }

    syncCustomerOrders(): Promise<BaseResponse> {
        return this.apiService.get<BaseResponse>(this.apiService.API.BE.SYNC_CUSTOMER_ORDERS).toPromise();
    }

    getCustomerOrdersGrouped(queryParams, groupBy: CUSTOMER_ORDER_GROUP): Promise<MetaResult> {
        let url = buildRequest(this.apiService.API.BE.LIST_CUSTOMER_ORDERS_GROUPED,
            {
                ':group': groupBy
            });
        return this.apiService.post<MetaResult>(url, queryParams).toPromise();
    }

    // ORDINI LAVORAZIONE ODL
    //
    getWorkingOrders(queryParams): Promise<MetaResult> {
        return this.apiService.post<MetaResult>(this.apiService.API.BE.LIST_WORKING_ORDERS, queryParams).toPromise();
    }

    syncWorkingOrders(): Promise<BaseResponse> {
        return this.apiService.get<BaseResponse>(this.apiService.API.BE.SYNC_WORKING_ORDERS).toPromise();
    }

    // PROGRAMMI DI PRODUZIONE (BY DAY)
    //
    getProductionPlans(queryParams): Promise<MetaResult> {
        return this.apiService.post<MetaResult>(this.apiService.API.BE.LIST_PRODUCTION_PLANS, queryParams).toPromise();
    }

    syncProductionPlans(productionDate: string): Promise<BaseResponse> {
        let url = buildRequest(this.apiService.API.BE.SYNC_PRODUCTION_PLANS,
            {
                ':date': productionDate
            });
        return this.apiService.get<BaseResponse>(url).toPromise();
    }

    // PIANI DI PRODUZIONE (BY WEEK)
    //
    getWorkingPlans(queryParams): Promise<MetaResult> {
        return this.apiService.post<MetaResult>(this.apiService.API.BE.LIST_WORKING_PLANS, queryParams).toPromise();
    }

    getOperatorAgenda(queryParams): Promise<MetaResult> {
        return this.apiService.post<MetaResult>(this.apiService.API.BE.LIST_OPERATOR_AGENDA, queryParams).toPromise();
    }

    getOperatorPlansInfo(queryParams): Promise<MetaResult> {
        return this.apiService.post<MetaResult>(this.apiService.API.BE.GET_OPERATOR_PLANS_INFO, queryParams).toPromise();
    }

    getMissingActivations(queryParams): Promise<MetaResult> {
        return this.apiService.post<MetaResult>(this.apiService.API.BE.GET_MISSING_ACTIVATION, queryParams).toPromise();
    }

    syncWorkingPlans(productionDate: string): Promise<BaseResponse> {
        let url = buildRequest(this.apiService.API.BE.SYNC_WORKING_PLANS,
            {
                ':date': productionDate
            });
        return this.apiService.get<BaseResponse>(url).toPromise();
    }

    getWorkingPlansItemFinalProducts(id: string, code_sl: string, type: FINAL_PROD_TYPE): Promise<MetaResult> {     // torna tutti prodotti finiti in ordine per il S\L (id = codice articolo semilavorato)
        let body = {
            id: id,
            code_sl: code_sl,
            type: type
        };
        return this.apiService.post<MetaResult>(this.apiService.API.BE.WORKING_PLANS_FINAL_PRODUCTS, body).toPromise();
    }


    // CENTRI DI LAVORO (FORNI)
    //
    getWorkingCenters(queryParams): Promise<MetaResult> {
        return this.apiService.post<MetaResult>(this.apiService.API.BE.LIST_WORKING_CENTERS, queryParams).toPromise();
    }

    syncWorkingCenters(): Promise<BaseResponse> {
        return this.apiService.get<BaseResponse>(this.apiService.API.BE.SYNC_WORKING_CENTERS).toPromise();
    }

    // GESTIONE TURNI
    //
    getWorkingShifts(queryParams): Promise<MetaResult> {
        return this.apiService.post<MetaResult>(this.apiService.API.BE.LIST_WORKING_SHIFTS, queryParams).toPromise();
    }

    createOrUpdateWorkingShift(workingShift: any): Promise<BaseResponse> {
        return this.apiService.post<BaseResponse>(this.apiService.API.BE.ENTRY_WORKING_SHIFT, workingShift).toPromise();
    }

    getWorkingShiftById(id): Promise<MetaResult> {
        let url = buildRequest(this.apiService.API.BE.WORKING_SHIFT_ID,
            {
                ':id': id
            });

        return this.apiService.get<MetaResult>(url).toPromise();
    }

    deleteWorkingShiftById(id): Promise<MetaResult> {
        let url = buildRequest(this.apiService.API.BE.WORKING_SHIFT_ID,
            {
                ':id': id
            });

        return this.apiService.delete<MetaResult>(url).toPromise();
    }

    // WORKFLOW PRODUZIONE
    //
    getResourcePlans(id: string, date: string): Promise<MetaResult> {
        let url = buildRequest(this.apiService.API.BE.PROD_WORKFLOW_RESOURCE_PLANS,
            {
                ':id': id,
                ':date': date
            });

        return this.apiService.get<MetaResult>(url).toPromise();
    }


    getResourcePlanItemActions(id: string, qr: string, code: string): Promise<MetaResult> {
        let url = buildRequest(this.apiService.API.BE.PROD_WORKFLOW_RESOURCE_ITEM_ACTIONS,
            {
                ':id': id,
                ':qr': qr,
                ':code': code.replace('/', '%2F')
            });

        return this.apiService.get<MetaResult>(url).toPromise();
    }

    getResourcePlanItemActionsEx(id: string, qr: string, code: string): Promise<MetaResult> {
        let url = buildRequest(this.apiService.API.BE.PROD_WORKFLOW_RESOURCE_ITEM_ACTIONS_EX,
            {
                ':id': id,
                ':qr': qr
            });

        return this.apiService.post<MetaResult>(url, { code: code }).toPromise();
    }

    activateResourcePlanItem(id: string, qr: string, item: any): Promise<MetaResult> {
        let url = buildRequest(this.apiService.API.BE.PROD_WORKFLOW_RESOURCE_ACTIVATE_PLAN_ITEM,
            {
                ':id': id,
                ':qr': qr
            });

        return this.apiService.put<MetaResult>(url, item).toPromise();
    }

    manualActivateResourcePlanItem(id: string, qr: string, productCode: string, activatingOperatorId: string): Promise<MetaResult> {
        let url = buildRequest(this.apiService.API.BE.PROD_WORKFLOW_RESOURCE_MANUAL_ACTIVATE_PLAN_ITEM,
            {
                ':id': id,
                ':qr': qr
            });

        return this.apiService.put<MetaResult>(url, { productCode: productCode, activatingOperatorId: activatingOperatorId }).toPromise();
    }

    getPlanItem(qr: string): Promise<MetaResult> {
        let url = buildRequest(this.apiService.API.BE.PROD_WORKFLOW_ITEM_BY_CODE,
            {
                ':qr': qr
            });

        return this.apiService.get<MetaResult>(url).toPromise();
    }

    updatePlanItemStatus(qr: string, status: PRODUCT_STATUS): Promise<MetaResult> {
        let url = buildRequest(this.apiService.API.BE.PROD_WORKFLOW_ITEM_BY_CODE,
            {
                ':qr': qr
            });

        return this.apiService.put<MetaResult>(url, { status: status }).toPromise();
    }

    getOperatorCapacity(date: string, data: any): Promise<MetaResult> {
        let url = buildRequest(this.apiService.API.BE.PROD_WORKFLOW_OPERATOR_CAPACITY,
            {
                ':date': date
            });

        return this.apiService.post<MetaResult>(url, data).toPromise();
    }

    assignProduction(data: any): Promise<BaseResponse> {
        return this.apiService.post<BaseResponse>(this.apiService.API.BE.PROD_WORKFLOW_ASSIGN, data).toPromise();
    }

    updateProduction(data: any): Promise<BaseResponse> {
        return this.apiService.post<BaseResponse>(this.apiService.API.BE.PROD_WORKFLOW_UPDATE, data).toPromise();
    }

    getOperatorProductionItems(): Promise<MetaResult> {
        return this.apiService.get<MetaResult>(this.apiService.API.BE.PROD_WORKFLOW_OPERATOR_ITEMS).toPromise();
    }

    operatorCheckActivateProductionItem(qr: string): Promise<MetaResult> {
        return this.apiService.post<MetaResult>(this.apiService.API.BE.PROD_WORKFLOW_OPERATOR_CHECK_ACTIVATE_ITEM, { qr: qr }).toPromise();
    }

    operatorActivateProductionItem(id: string, qr: string): Promise<MetaResult> {
        return this.apiService.post<MetaResult>(this.apiService.API.BE.PROD_WORKFLOW_OPERATOR_ACTIVATE_ITEM, { id: id, qr: qr }).toPromise();
    }

    operatorDeactivateProductionItem(qr: string): Promise<MetaResult> {
        return this.apiService.post<MetaResult>(this.apiService.API.BE.PROD_WORKFLOW_OPERATOR_DEACTIVATE_ITEM, { qr: qr }).toPromise();
    }

    getOperatorProductionItemActions(qr: string): Promise<MetaResult> {
        return this.apiService.post<MetaResult>(this.apiService.API.BE.PROD_WORKFLOW_OPERATOR_ITEM_ACTION, { qr: qr }).toPromise();
    }

    operatorProductionItemChangeState(qr: string, state: string, note: string, defect: string): Promise<MetaResult> {
        return this.apiService.post<MetaResult>(this.apiService.API.BE.PROD_WORKFLOW_OPERATOR_ITEM_STATE, { qr: qr, state: state, note: note, defect: defect }).toPromise();
    }

    operatorDeleteProductionItem(id: string): Promise<MetaResult> {
        let url = buildRequest(this.apiService.API.BE.PROD_WORKFLOW_OPERATOR_ITEM,
            {
                ':id': id
            });

        return this.apiService.delete<MetaResult>(url).toPromise();
    }

    itemsToMoveInProduction(): Promise<MetaResult> {
        return this.apiService.get<MetaResult>(this.apiService.API.BE.PROD_WORKFLOW_ITEMS_MOVE_TO_PRODUCTION).toPromise();
    }

    itemsCommitMoveInProduction(item): Promise<MetaResult> {
        return this.apiService.post<MetaResult>(this.apiService.API.BE.PROD_WORKFLOW_ITEMS_MOVE_TO_PRODUCTION, item).toPromise();
    }

    validateContainerQR(qr): Promise<MetaResult> {
        return this.apiService.post<MetaResult>(this.apiService.API.BE.PROD_WORKFLOW_VALIDATE_CONTAINER, { qr: qr }).toPromise();
    }

    itemActionInContainer(action, containerId, activationId): Promise<MetaResult> {
        let body = {
            action: action,
            containerId: containerId,
            activationId: activationId
        };
        return this.apiService.post<MetaResult>(this.apiService.API.BE.PROD_WORKFLOW_ITEM_CONTAINER_ACTION, body).toPromise();
    }

    validateAndCreatePackageQR(qr, packageTypeId): Promise<MetaResult> {
        return this.apiService.post<MetaResult>(this.apiService.API.BE.PROD_WORKFLOW_VALIDATE_PACKAGE, { qr: qr, packageTypeId: packageTypeId }).toPromise();
    }

    itemActionInPackageInstance(action, packageQR, productQR): Promise<MetaResult> {
        let body = {
            action: action,
            packageQR: packageQR,
            productQR: productQR
        };
        return this.apiService.post<MetaResult>(this.apiService.API.BE.PROD_WORKFLOW_ITEM_PACKAGE_ACTON, body).toPromise();
    }

    inspectPackageInstance(packageQR: any): Promise<MetaResult> {
        return this.apiService.post<MetaResult>(this.apiService.API.BE.PACKAGE_INSTANCE_INSPECT, { packageQR: packageQR }).toPromise();
    }




    // PACKAGE TYPES
    //
    getPackageTypes(queryParams): Promise<MetaResult> {
        return this.apiService.post<MetaResult>(this.apiService.API.BE.PACKAGE_TYPES, queryParams).toPromise();
    }

    createOrUpdatePackageType(packageType: any): Promise<BaseResponse> {
        return this.apiService.post<BaseResponse>(this.apiService.API.BE.PACKAGE_TYPE, packageType).toPromise();
    }

    getPackageTypesById(id): Promise<MetaResult> {
        let url = buildRequest(this.apiService.API.BE.PACKAGE_TYPE_ID,
            {
                ':id': id
            });

        return this.apiService.get<MetaResult>(url).toPromise();
    }

    deletePackageTypeById(id): Promise<MetaResult> {
        let url = buildRequest(this.apiService.API.BE.PACKAGE_TYPE_ID,
            {
                ':id': id
            });

        return this.apiService.delete<MetaResult>(url).toPromise();
    }

    importPackageTypesFromFile(file: File): Promise<any> {
        let formData: FormData = new FormData();
        formData.append("file", file, file.name);

        let req = new HttpRequest("POST", this.apiService.resolveApiUrl(this.apiService.API.BE.PACKAGE_TYPE_IMPORT), formData, {
            reportProgress: true
        });

        return this.http.request(req).toPromise();
    }

    // SHIPPING AREAS
    //
    getShippingAreas(queryParams): Promise<MetaResult> {
        return this.apiService.post<MetaResult>(this.apiService.API.BE.SHIPPING_AREAS, queryParams).toPromise();
    }

    createOrUpdateShippingArea(shippingArea: any): Promise<BaseResponse> {
        return this.apiService.post<BaseResponse>(this.apiService.API.BE.SHIPPING_AREA, shippingArea).toPromise();
    }

    getShippingAreaById(id): Promise<MetaResult> {
        let url = buildRequest(this.apiService.API.BE.SHIPPING_AREA_ID,
            {
                ':id': id
            });

        return this.apiService.get<MetaResult>(url).toPromise();
    }

    deleteShippingAreaById(id): Promise<MetaResult> {
        let url = buildRequest(this.apiService.API.BE.SHIPPING_AREA_ID,
            {
                ':id': id
            });

        return this.apiService.delete<MetaResult>(url).toPromise();
    }

    // EXTRA ACTIVITIES
    //
    getExtraActivities(queryParams): Promise<MetaResult> {
        return this.apiService.post<MetaResult>(this.apiService.API.BE.EXTRA_ACTIVITIES, queryParams).toPromise();
    }

    createOrUpdateExtraActivity(extraActivity: any): Promise<BaseResponse> {
        return this.apiService.post<BaseResponse>(this.apiService.API.BE.EXTRA_ACTIVITY, extraActivity).toPromise();
    }

    getExtraActivityById(id): Promise<MetaResult> {
        let url = buildRequest(this.apiService.API.BE.EXTRA_ACTIVITY_ID,
            {
                ':id': id
            });

        return this.apiService.get<MetaResult>(url).toPromise();
    }

    deleteExtraActivityById(id): Promise<MetaResult> {
        let url = buildRequest(this.apiService.API.BE.EXTRA_ACTIVITY_ID,
            {
                ':id': id
            });

        return this.apiService.delete<MetaResult>(url).toPromise();
    }

    // PRODUCT DEFECTS
    //
    getProductDefects(queryParams): Promise<MetaResult> {
        return this.apiService.post<MetaResult>(this.apiService.API.BE.PRODUCT_DEFECTS, queryParams).toPromise();
    }

    createOrUpdateProductDefect(extraActivity: any): Promise<BaseResponse> {
        return this.apiService.post<BaseResponse>(this.apiService.API.BE.PRODUCT_DEFECT, extraActivity).toPromise();
    }

    getProductDefectById(id, scope): Promise<MetaResult> {
        let url = buildRequest(this.apiService.API.BE.PRODUCT_DEFECT_ID,
            {
                ':id': id,
                ':scope': scope
            });

        return this.apiService.get<MetaResult>(url).toPromise();
    }

    deleteProductDefectById(id, scope): Promise<MetaResult> {
        let url = buildRequest(this.apiService.API.BE.PRODUCT_DEFECT_ID,
            {
                ':id': id,
                ':scope': scope
            });

        return this.apiService.delete<MetaResult>(url).toPromise();
    }

    // PRODUCT CONTAINERS
    //
    getProductContainers(queryParams): Promise<MetaResult> {
        return this.apiService.post<MetaResult>(this.apiService.API.BE.PRODUCT_CONTAINERS, queryParams).toPromise();
    }

    createOrUpdateProductContainer(container: any): Promise<BaseResponse> {
        return this.apiService.post<BaseResponse>(this.apiService.API.BE.PRODUCT_CONTAINER, container).toPromise();
    }

    getProductContainerById(id): Promise<MetaResult> {
        let url = buildRequest(this.apiService.API.BE.PRODUCT_CONTAINER_ID,
            {
                ':id': id
            });

        return this.apiService.get<MetaResult>(url).toPromise();
    }

    deleteProductContainerById(id): Promise<MetaResult> {
        let url = buildRequest(this.apiService.API.BE.PRODUCT_CONTAINER_ID,
            {
                ':id': id
            });

        return this.apiService.delete<MetaResult>(url).toPromise();
    }

    // PACKAGE INSTANCES
    //
    getPackageInstances(queryParams): Promise<MetaResult> {
        return this.apiService.post<MetaResult>(this.apiService.API.BE.PACKAGE_INSTANCES, queryParams).toPromise();
    }

    createOrUpdatePackageInstance(container: any): Promise<BaseResponse> {
        return this.apiService.post<BaseResponse>(this.apiService.API.BE.PACKAGE_INSTANCE, container).toPromise();
    }

    // ORDER DESTINATIONS
    getOrderDestinations(queryParams): Promise<MetaResult> {
        return this.apiService.post<MetaResult>(this.apiService.API.BE.ORDER_DESTINATIONS, queryParams).toPromise();
    }
















    /////////////
    // DEPRECATED
    /////////////
    async getOrders(): Promise<OrderListResult> {
        return this.apiService.get<OrderListResult>(this.apiService.API.BE.ORDER_LIST).toPromise();
    }

    async getOrder(id: string): Promise<OrderResult> {
        let url = buildRequest(this.apiService.API.BE.ORDER_BY_ID,
            {
                ':id': id
            });
        return this.apiService.get<OrderResult>(url).toPromise();
    }

    async createOrUpdateOrder(order: Order): Promise<BaseResponse> {
        return this.apiService.post<BaseResponse>(this.apiService.API.BE.ORDER, order).toPromise();
    }

    async deleteOrder(id: string): Promise<BaseResponse> {
        let url = buildRequest(this.apiService.API.BE.ORDER_BY_ID,
            {
                ':id': id
            });
        return this.apiService.delete<BaseResponse>(url).toPromise();
    }

    async getOrderWithProduct(productId): Promise<OrderResult> {
        let url = buildRequest(this.apiService.API.BE.ORDER_WITH_PRODUCT,
            {
                ':productId': productId
            });
        return this.apiService.get<OrderResult>(url).toPromise();
    }

    async updateOrderProduct(productId, data: any): Promise<OrderResult> {
        let url = buildRequest(this.apiService.API.BE.UPDATE_ORDER_PRODUCT,
            {
                ':productId': productId
            });
        let body = {
            data: data
        }
        return this.apiService.put<OrderResult>(url, body).toPromise();
    }

    async activateProduct(orderId: string, itemId: string, codeId: string): Promise<ProductResult> {
        let url = buildRequest(this.apiService.API.BE.PRODUCT_ACTIVATE,
            {
                ':orderId': orderId,
                ':itemId': itemId,
                ':codeId': codeId
            });
        let body = {
            status: PRODUCT_STATUS.ACTIVE
        }
        return this.apiService.post<ProductResult>(url, body).toPromise();
    }

    async updateProductStatus(codeId: string, productStatus: PRODUCT_STATUS): Promise<ProductResult> {
        let url = buildRequest(this.apiService.API.BE.PRODUCT_UPDATE,
            {
                ':codeId': codeId
            });
        let body = {
            status: productStatus
        }
        return this.apiService.post<ProductResult>(url, body).toPromise();
    }

    async getOrderProducts(orderId: string): Promise<OrderProductsResult> {
        let url = buildRequest(this.apiService.API.BE.ORDER_PRODUCTS,
            {
                ':orderId': orderId
            });
        return this.apiService.get<OrderProductsResult>(url).toPromise();
    }

    async getProductByCode(codeId: string): Promise<OrderProductResult> {
        let url = buildRequest(this.apiService.API.BE.PRODUCT_BY_CODE,
            {
                ':codeId': codeId
            });
        return this.apiService.get<OrderProductResult>(url).toPromise();
    }

    async getProducts(): Promise<OrderProductsResult> {
        return this.apiService.get<OrderProductsResult>(this.apiService.API.BE.ALL_PRODUCTS).toPromise();
    }

    async getDashboardStats(): Promise<DashboardResult> {
        return this.apiService.get<DashboardResult>(this.apiService.API.BE.DASHBOARD_INFO).toPromise();
    }

    async updateProductOperationLog(codeId: string, productStatus: PRODUCT_STATUS): Promise<ProductResult> {
        let url = buildRequest(this.apiService.API.BE.PRODUCT_OPERATION_UPDATE,
            {
                ':codeId': codeId
            });
        let body = {
            status: productStatus
        }
        return this.apiService.post<ProductResult>(url, body).toPromise();
    }




}
