import { Component, ElementRef, Inject, OnInit, Renderer2, ViewChild } from '@angular/core';
import { MatSelectChange } from '@angular/material/select';
import { MatTabGroup } from '@angular/material/tabs';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { CameraAvailable, CameraType, QrScannerComponent } from 'projects/fe-common/src/lib/components/qr-scanner/qr-scanner.component';
import { QrScanContext } from 'projects/fe-common/src/lib/models/app';
import { GreenPass, GreenPassSettings, GreenPassValidation, GREENPASS_LEVEL } from 'projects/fe-common/src/lib/models/greenpass';
import { ACCESS_CONTROL_LEVEL, Person } from 'projects/fe-common/src/lib/models/person';
import { AdminUserManagementService } from 'projects/fe-common/src/lib/services/admin-user-management.service';
import { CommonService } from 'projects/fe-common/src/lib/services/common.service';
import { NotifyManagementService } from 'projects/fe-common/src/lib/services/notify-management.service';
import { SettingsManagementService } from 'projects/fe-common/src/lib/services/settings-management.service';
import { UserActionManagementService } from 'projects/fe-common/src/lib/services/user-action-management.service';
import { UserActionService, USER_ACTIVITY_TYPE } from 'projects/fe-common/src/lib/services/user-action.service';
import { UserManagementService } from 'projects/fe-common/src/lib/services/user-management.service';
import { EnvironmentService } from 'projects/fe-touchpoint/src/app/services/environment.service';
import { Subscription } from 'rxjs';
import { BrowserMultiFormatReader } from '@zxing/library';
import { BrowserQRCodeReader, BrowserQRCodeSvgWriter } from '@zxing/browser';
import { MatBottomSheet, MAT_BOTTOM_SHEET_DATA } from '@angular/material/bottom-sheet';
import { GreenpassChooseComponent } from '../../components/greenpass-choose/greenpass-choose.component';
import { ImageCroppedEvent } from 'ngx-image-cropper';
import { CropImageComponent } from '../../components/crop-image/crop-image.component';
import { GreenpassScannerComponent } from '../../components/greenpass-scanner/greenpass-scanner.component';
import { Company } from 'projects/fe-common/src/lib/models/company';
import { I } from '@angular/cdk/keycodes';
import { ThrowStmt } from '@angular/compiler';
import { BookableAssetsManagementService, ResourceAction, ResourceSearchAvailabilityResponse, UserResourceInteractionResponse } from 'projects/fe-common-v2/src/lib/services/bookable-assets-management.service';
import { Site } from 'projects/fe-common/src/lib/models/admin/site';
import { AdminSiteManagementService } from 'projects/fe-common/src/lib/services/admin-site-management.service';
import { EventManagementActionService } from 'projects/fe-common-v2/src/lib/services/event-management-action.service';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DataStorageManagementService } from 'projects/fe-common-v2/src/lib/services/data-storage-management.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { UserDocument } from 'projects/fe-common-v2/src/lib/models/user-document';

@Component({
    selector: 'app-upload-document-page',
    templateUrl: './upload-document-page.component.html',
    styleUrls: ['./upload-document-page.component.scss']
})
export class UploadDocumentPageComponent implements OnInit {
    @ViewChild('fileInput', { static: false }) file: ElementRef;

    availableDevices: CameraAvailable[] = [
        { name: 'Front Camera', type: CameraType.Front },
        { name: 'Back Camera', type: CameraType.Back }
    ];

    currAccount: Person;

    defaultCamera: string;
    currentDeviceId: string;
    currentCamera: CameraType = CameraType.Back;
    showCameraSelection: boolean = false;
    documentId = '';
    documentName = '';
    expirationDateNeeded = false;
    expirationDate: Date = null;

    imageUrl: string | ArrayBuffer = "";

    currentCompany: Company;

    uploadedFile: any;
    today = new Date();

    constructor(
        private _route: ActivatedRoute,
        private _router: Router,
        private _env: EnvironmentService,
        private _notifyManagementService: NotifyManagementService,
        private _common: CommonService,
        private _userManagementService: UserManagementService,
        private _settingsManagementService: SettingsManagementService,
        private _userActionManagementService: UserActionManagementService,
        private _adminUserManagementService: AdminUserManagementService,
        private _adminSiteManagementService: AdminSiteManagementService,
        private bookableAssetsManagementService: BookableAssetsManagementService,
        private eventManagementActionService: EventManagementActionService,
        private dataStorageManagementService: DataStorageManagementService,
        private snackBar: MatSnackBar,
        private bottomSheet: MatBottomSheet,
        public translate: TranslateService,
        private _bottomSheet: MatBottomSheet
    ) {
        let savedCamera = this._settingsManagementService.getSettingsValue('default-camera');
        if ((savedCamera != CameraType.Front) && (savedCamera != CameraType.Back)) {
            this.currentCamera = CameraType.Back;
            this._settingsManagementService.setSettingsValue('default-camera', this.currentCamera);
        } else
            this.currentCamera = savedCamera as CameraType;

        this.documentId = this._route.snapshot.paramMap.get('documentId');
        this.documentName = this._route.snapshot.paramMap.get('documentName');
        let expirationDateNeededParam = this._route.snapshot.paramMap.get('expirationNeeded');
        expirationDateNeededParam == 'Yes' ? this.expirationDateNeeded = true : null;
    }

    async ngOnInit() {
        this.currAccount = this._userManagementService.getAccount();
        let result = await this._adminUserManagementService.getPeople(this.currAccount.companyId);
        for (let person of result.people) {
            if (person.id == this.currAccount.id) {
                this.currAccount = person;
                break;
            }
        }
        this.currentCompany = (await this._adminUserManagementService.getCompany(this.currAccount.companyId)).company;
        if (this.currAccount) {
            let res = await this._adminUserManagementService.getPeople(this.currentCompany.id);
            let people = res.people;
            this.currAccount = people.filter(person => person.id == this.currAccount.id)[0];
        }
    }

    onDeviceSelectChange(event: MatSelectChange) {
        console.log('onDeviceSelectChange: ', event);

        this.currentCamera = event.value;
        this._settingsManagementService.setSettingsValue('default-camera', event.value);
    }

    chooseFile() {
        if (this.file.nativeElement.files.length > 0) {
            this.file.nativeElement.value = '';
        }
        this.file.nativeElement.click();
    }

    onFileChosen(file: any) {
        this.uploadedFile = file;
        console.log(file);
    }

    onBack() {
        this._router.navigate(['user-profile']);
    }

    async onConfirm() {
        if(this.uploadedFile) {
            if(this.expirationDateNeeded) {
                if(this.expirationDate) {
                    let res = await this.dataStorageManagementService.uploadFile(this.uploadedFile);
                    if (res.body.result) {
                        let userDoc = UserDocument.Empty();
                        userDoc.documentId = this.documentId;
                        userDoc.userId = this.currAccount.id;
                        userDoc.fileName = this.uploadedFile.name;
                        userDoc.staffDocumentName = this.documentName;
                        userDoc.expirationDate = new Date(this.expirationDate);
                        userDoc.fileId = res.body.fileId;
                        let res2 = await this._adminUserManagementService.addUpdateUserDocument(userDoc);
                        await this._adminUserManagementService.generateAccessControlQrCode(this.currAccount.id);
                        this.snackBar.open(this.translate.instant('DocumentUploaded'), this.translate.instant('GENERAL.OK'), { duration: 3000 });
                    }
                    this._router.navigate(['user-profile']);
                }
                else {
                    this.snackBar.open(this.translate.instant('PleaseSelectExpirationDate'), this.translate.instant('GENERAL.OK'), { duration: 3000 });
                }
            }
            else {
                let res = await this.dataStorageManagementService.uploadFile(this.uploadedFile);
                if (res.body.result) { 
                    let userDoc = UserDocument.Empty();
                    userDoc.documentId = this.documentId;
                    userDoc.userId = this.currAccount.id;
                    userDoc.fileName = this.uploadedFile.name;
                    userDoc.staffDocumentName = this.documentName;
                    userDoc.fileId = res.body.fileId;
                    let res2 = await this._adminUserManagementService.addUpdateUserDocument(userDoc);
                    this.snackBar.open(this.translate.instant('DocumentUploaded'), this.translate.instant('GENERAL.OK'), { duration: 3000 });
                }
                this._router.navigate(['user-profile']);
            }

        }
        else {
            this.snackBar.open(this.translate.instant('PleaseUploadFile'), this.translate.instant('GENERAL.OK'), { duration: 3000 });
        }

    }

    fileBrowseHandler(files: any[]) {
        this.uploadedFile = files[0];
    }

    dateChangeEvent($event: any) {
        this.expirationDate = new Date($event.value);
    }
}
