<h2 mat-dialog-title><b>{{ getTitle() }}</b></h2>

<mat-dialog-content>
    <div class="dialog-container">
        <div class="action-container">
            <div class="action-button" (click)="onUpdateQuantity(false)">
                <i class="material-icons white-text button-icon action-image">remove_circle_outline</i>
            </div>
            <div class="action-text">
                {{ getQuantityInfo() }}
            </div>
            <div class="action-button" (click)="onUpdateQuantity(true)">
                <i class="material-icons white-text button-icon action-image">add_circle_outline</i>
            </div>
        </div>
    </div>
</mat-dialog-content>

<mat-dialog-actions align="end">
    <button mat-flat-button class="action-button" color="primary"
        (click)="onCancelClick()">{{'GENERAL.CANCEL'|translate}}</button>
    <button mat-flat-button class="action-button" color="primary" cdkFocusInitial
        (click)="onConfirmClick()">{{'GENERAL.CONFIRM'|translate}}</button>
</mat-dialog-actions>