import { Component, OnInit, ViewChild } from '@angular/core';
import { MatBottomSheet } from '@angular/material/bottom-sheet';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatTabGroup } from '@angular/material/tabs';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { CameraType, QrScannerComponent } from 'projects/fe-common-v2/src/lib/components/qr-scanner/qr-scanner.component';
import { ITEM_STATUS, Order, OrderItem, Plan, PlanItem, Product, PRODUCT_STATUS } from 'projects/fe-common-v2/src/lib/models/product-tracking/products-tracking';
import { AdminSiteManagementService } from 'projects/fe-common-v2/src/lib/services/admin-site-management.service';
import { CommonService } from 'projects/fe-common-v2/src/lib/services/common.service';
import { NotifyManagementService } from 'projects/fe-common-v2/src/lib/services/notify-management.service';
import { ProductsTrackingService } from 'projects/fe-common-v2/src/lib/services/products-tracking.service';
import { SettingsManagementService } from 'projects/fe-common-v2/src/lib/services/settings-management.service';
import { UserManagementService } from 'projects/fe-common-v2/src/lib/services/user-management.service';
import { v4 as uuidv4 } from 'uuid';
import { ProductTrackingActionSheetComponent, UIMode } from '../product-tracking-action-sheet/product-tracking-action-sheet.component';

enum UI_MODE {
    uiModeStart,
    uiModePF,
    uiModeSL,
    uiModeDeactivateSL,
    uiMoveIntoContainer,
    uiMoveOutsideContainer,
}

enum SCAN_STATE {
    ssResource,
    ssProduct,
    ssContainer,
    ssContainerSL
}

enum ACTION_STATE {
    asStartOrder,
    asOrderActive,
    asCloseOrder,
    asOrderDead

}

class ProductActivationCommand {
    public productCode: string;
    public productDescr: string;
    public quantity: number;
    public done: number;
}

@Component({
    selector: 'app-product-tracking-page',
    templateUrl: './product-tracking-page.component.html',
    styleUrls: ['./product-tracking-page.component.scss']
})
export class ProductTrackingPageComponent implements OnInit {
    CURRENT_RESOURCE_KEY = 'active-resource';

    @ViewChild(MatTabGroup, { static: false }) tabgroup: MatTabGroup;
    @ViewChild('scanner') qrScanner: QrScannerComponent;

    ACTION_STATE = ACTION_STATE;
    UI_MODE = UI_MODE;
    SCAN_STATE = SCAN_STATE;

    currentUIMode: UI_MODE;
    currentActionState: ACTION_STATE;

    scanning: boolean = false;
    currentCamera: CameraType = CameraType.Back;

    currentScanState: SCAN_STATE;
    currentActiveResource: any;
    currentOrderProducts: PlanItem[] = [];

    isItemComplete = (item: PlanItem) => (item.status == PRODUCT_STATUS.ELIGIBLE) || (item.status == PRODUCT_STATUS.PACKAGING) || (item.status == PRODUCT_STATUS.SHIP);

    productActivationCommands: ProductActivationCommand[] = [];
    currentProductItemCode: string;
    currentProductItemData: any;
    operatorProductionItems: any[] = [];

    selectedContainer: any = null;
    deadMessage: string = '';
    activeResource: string = 'N/A';

    showProductionItems: boolean = true;
    productionActivities: any[] = [];
    currentFinalProductItem: any = null;

    constructor(private commonService: CommonService,
        private adminSiteManagementService: AdminSiteManagementService,
        private productsTrackingService: ProductsTrackingService,
        private userManagementService: UserManagementService,
        private bottomSheet: MatBottomSheet,
        private settingsManagementService: SettingsManagementService,
        private notifyManagementService: NotifyManagementService,
        private snackBar: MatSnackBar,
        private dialog: MatDialog,
        public translate: TranslateService,
        private router: Router) { }

    async ngOnInit() {
        this.currentUIMode = UI_MODE.uiModeStart;
    }

    async onSelectMode(mode) {
        if (mode == UI_MODE.uiModeSL) {
            await this.checkOperatorResourcePlan();
        } else if (mode == UI_MODE.uiModePF) {
            this.operatorProductionItems = await this.getOperatorProductionItems();
        } else if ((mode == UI_MODE.uiMoveIntoContainer) || (mode == UI_MODE.uiMoveOutsideContainer)) {
            this.currentScanState = SCAN_STATE.ssContainer;
        }

        this.currentUIMode = mode;
    }

    async getOperatorProductionItems() {
        let res = await this.productsTrackingService.getOperatorProductionItems();
        if (this.commonService.isValidResponse(res)) {
            return res.data;
        } else {
            await this.notifyManagementService.openMessageDialog('ATTENZIONE', 'Si è verificato un errore: ' + res.reason);
            return [];
        }
    }

    async checkOperatorResourcePlan() {     // controllo se l'operatore ha avviato una produzione di SL dal forno
        this.activeResource = this.settingsManagementService.getSettingsValue(this.CURRENT_RESOURCE_KEY);
        if (this.activeResource) {
            let today = this.commonService.toYYYYMMDD(new Date());
            let res = await this.productsTrackingService.getResourcePlans(this.activeResource, today);
            if (this.commonService.isValidResponse(res)) {

                this.currentActiveResource = res.meta.data;
                this.productActivationCommands = this.currentActiveResource.planData.productActivationCommands;

                if (this.currentActiveResource.isClosed)
                    this.currentActionState = ACTION_STATE.asCloseOrder;
                else
                    this.currentActionState = ACTION_STATE.asOrderActive;
            } else {
                // await this.notifyManagementService.openMessageDialog('ATTENZIONE', 'Si è verificato un errore: ' + res.reason);
                this.deadMessage = res.reason;
                this.currentActionState = ACTION_STATE.asOrderDead;
            }
        } else {
            this.currentActionState = ACTION_STATE.asStartOrder;
        }
    }

    isValidQR(qrKey: string, qrValue: string): boolean {
        return qrValue.includes(qrKey);
    }

    unpackQR(data: string) {
        let split = data.split('/');
        if (split.length < 1)
            return null;
        return split[split.length - 1];
    }

    getActionStyle(isexit: boolean = false) {
        return {
            'background': isexit ? '#ff0000' : '#0640A9',
            'color': '#0640A9'
        }
    }

    getActivityStyle() {
        return {
            'background': '#06a965',
            'color': '#0640A9'
        }
    }

    onStartResourceProduction() {
        this.currentScanState = SCAN_STATE.ssResource;
        this.tabgroup.selectedIndex = 1;
        this.qrScanner.openUserMedia();
    }

    onShowProductionActivities(productionItem: any) {
        this.currentFinalProductItem = productionItem;
        this.productionActivities = productionItem.extraActivities.map(xa => {
            return {
                activityName: xa.activityType,
                activityTime: xa.activityTime,
                quantity: xa.targetQty
            }
        })
        this.showProductionItems = false;
    }

    async onStartProduct() {
        if (!this.currentFinalProductItem) {
            await this.notifyManagementService.openMessageDialog('ATTENZIONE', 'Si è verificato un errore nella selezione del prodotto, si prega di riprovare.');
            return;
        }

        this.currentScanState = SCAN_STATE.ssProduct;
        this.tabgroup.selectedIndex = 1;
        this.currentProductItemCode = this.currentFinalProductItem.id;
        this.qrScanner.openUserMedia();
    }

    onAbandonProduct() {
        this.showProductionItems = true;

    }

    onActivationCommand(command) {
        this.currentScanState = SCAN_STATE.ssProduct;
        this.tabgroup.selectedIndex = 1;
        this.currentProductItemData = command;
        this.qrScanner.openUserMedia();
    }

    async doQRAction(qr: string) {
        switch (this.currentScanState) {
            case SCAN_STATE.ssResource: {
                await this.onQRResourceAction(qr);
                break;
            }

            case SCAN_STATE.ssProduct: {
                await this.onQRProductAction(qr);
                break;
            }
        }
    }

    async onQRResourceAction(qr: string) {
        if (false && !this.isValidQR('resource', qr)) {
            // TODO Display error
            this.tabgroup.selectedIndex = 0;
            return;
        }

        let resourceId = this.unpackQR(qr);
        let today = this.commonService.toYYYYMMDD(new Date());
        let res = await this.productsTrackingService.getResourcePlans(resourceId, today);
        if (this.commonService.isValidResponse(res)) {
            this.settingsManagementService.setSettingsValue(this.CURRENT_RESOURCE_KEY, resourceId);

            this.currentActiveResource = res.meta.data;
            this.productActivationCommands = this.currentActiveResource.planData.productActivationCommands;

            this.currentActionState = ACTION_STATE.asOrderActive;
            this.tabgroup.selectedIndex = 0;
        } else {
            await this.notifyManagementService.openMessageDialog('ATTENZIONE', 'Il codice del centro di lavoro non è corretto oppure non ci sono prodotti pianificati.');
            this.tabgroup.selectedIndex = 0;
        }
    }

    async handleActivationStateEvent(productData: any, activationStatus: string, qr: string, note: string, defect: string) {
        console.log('handleChangeStateEvent: ', productData.productCode, activationStatus, qr);

        productData.status = activationStatus;
        productData.note = note;
        productData.defect = defect;

        let res = await this.productsTrackingService.activateResourcePlanItem(this.currentActiveResource.activationData.resource, qr, productData);
        if (this.commonService.isValidResponse(res)) {
            await this.notifyManagementService.openMessageDialog('INFO', 'L\'operazione sul semi-lavorato è stata registrata.');
            this.router.navigate(["home"]);
        } else {
            await this.notifyManagementService.openMessageDialog('ATTENZIONE', 'Si è verificato un errore: ' + res.reason);
        }
    }

    async onQRProductAction(qr: string) {
        if (false && !this.isValidQR('product-info', qr)) {
            // TODO Display error
            this.tabgroup.selectedIndex = 0;
            return;
        }
        let qrCode = this.unpackQR(qr);

        console.log('activation code: ', qrCode);
        if (this.currentUIMode == UI_MODE.uiModeSL) {
            let today = this.commonService.toYYYYMMDD(new Date());

            let res = await this.productsTrackingService.getResourcePlanItemActionsEx(this.currentActiveResource.activationData.resource, qrCode, this.currentProductItemData.productCode);
            if (this.commonService.isValidResponse(res)) {
                let bottomSheetRef = this.bottomSheet.open(ProductTrackingActionSheetComponent, {
                    data: {
                        mode: UIMode.SLActions,
                        doneEvent: null,
                        changeStateEvent: this.handleActivationStateEvent.bind(this),
                        productActions: res.data.actions,
                        productDefects: res.data.defectList,
                        productItem: res.data.item,
                        productWorkingData: null,
                        showNote: res.data.showNote,
                        showDefect: res.data.showDefect,
                        qr: qrCode
                    },
                    panelClass: 'bottom-sheet'
                });

                bottomSheetRef.afterDismissed().subscribe(() => {
                    this.qrScanner.openUserMedia();
                    this.scanning = false;
                });
            } else {
                await this.notifyManagementService.openMessageDialog('ATTENZIONE', 'Si è verificato un errore: ' + res.reason);
                this.qrScanner.openUserMedia();
                this.scanning = false;
            }

            /*
            let res = await this.productsTrackingService.activateResourcePlanItem(this.currentActiveResource.activationData.resource, qrCode, this.currentProductItemData);
            if (this.commonService.isValidResponse(res)) {
                await this.notifyManagementService.openMessageDialog('INFO', 'Il semi-lavorato è stato attivato');
                this.router.navigate(["home"]);
            } else {
                await this.notifyManagementService.openMessageDialog('ATTENZIONE', 'Si è verificato un errore: ' + res.reason);
            }
            */
        } else {
            let res = await this.productsTrackingService.operatorCheckActivateProductionItem(qrCode);
            if (this.commonService.isValidResponse(res)) {
                if (res.data?.slActivated == null) {
                    let answer = await this.notifyManagementService.openConfirmDialog('ATTENZIONE', 'Questo prodotto non è stato attivato come semilavorato. Si desidera ugualmente procedere con la lavorazione?');
                    if (!answer) {
                        this.router.navigate(["home"]);
                        return;
                    }
                }

                res = await this.productsTrackingService.operatorActivateProductionItem(this.currentProductItemCode, qrCode);
                if (this.commonService.isValidResponse(res)) {
                    this.router.navigate(["home"]);
                } else {
                    await this.notifyManagementService.openMessageDialog('ATTENZIONE', 'Si è verificato un errore: ' + res.reason);
                }
            } else {
                await this.notifyManagementService.openMessageDialog('ATTENZIONE', 'Si è verificato un errore: ' + res.reason);
            }
            this.tabgroup.selectedIndex = 0;
        }
    }

    async onCodeResult(resultString: string) {
        if ((resultString == null) || this.scanning)
            return;

        this.scanning = true;

        await this.doQRAction(resultString);

        this.scanning = false;
    }

    async onScanContainer(resultString: string) {
        if ((resultString == null) || this.scanning)
            return;

        this.scanning = true;
        this.qrScanner.openUserMedia();

        // verificare il QR code del container
        let res = await this.productsTrackingService.validateContainerQR(resultString);
        if (this.commonService.isValidResponse(res)) {
            await this.notifyManagementService.openMessageDialog('INFO', 'Il codice QR del container è valido.');

            this.selectedContainer = res.data;
            this.currentScanState = SCAN_STATE.ssContainerSL;
        } else {
            await this.notifyManagementService.openMessageDialog('ATTENZIONE', 'Si è verificato un errore: ' + res.reason);
        }

        this.scanning = false;
    }

    async onScanSL(resultString: string) {
        if ((resultString == null) || this.scanning)
            return;

        this.scanning = true;

        let action = 'ADD';
        if (this.currentUIMode == UI_MODE.uiMoveOutsideContainer)
            action = 'REMOVE';

        let res = await this.productsTrackingService.itemActionInContainer(action, this.selectedContainer.id, resultString);
        if (this.commonService.isValidResponse(res)) {
            await this.notifyManagementService.openMessageDialog('INFO', 'L\'operazione è stata registrata correttamente.');
        } else {
            await this.notifyManagementService.openMessageDialog('ATTENZIONE', 'Si è verificato un errore: ' + res.reason);
        }

        this.scanning = false;

        this.router.navigate(["home"]);
    }

    async onScanDeactivateSL(resultString: string) {
        if ((resultString == null) || this.scanning)
            return;

        this.scanning = true;

        let res = await this.productsTrackingService.operatorDeactivateProductionItem(resultString);
        if (this.commonService.isValidResponse(res)) {
            await this.notifyManagementService.openMessageDialog('INFO', 'L\'operazione è stata registrata correttamente.');
        } else {
            await this.notifyManagementService.openMessageDialog('ATTENZIONE', 'Si è verificato un errore: ' + res.reason);
        }

        this.scanning = false;

        this.router.navigate(["home"]);
    }

    async onBack() {
        this.qrScanner.closeVideoStream();

        if (this.tabgroup.selectedIndex == 1)
            this.tabgroup.selectedIndex = 0;
        else
            this.router.navigate(["home"]);
    }

    getPlanItemQuantity() {
        return this.currentActiveResource ? this.currentActiveResource.totalQty : 0;
    }

    getPlanItemActive() {
        return this.currentActiveResource ? this.currentActiveResource.totalActivated : 0;
    }

    getPlanItemEligible() {
        return this.currentActiveResource ? this.currentActiveResource.totalEligible : 0;
    }

    getPlanItemScrap() {
        return this.currentActiveResource ? this.currentActiveResource.totalScrap : 0;
    }

    async onCloseOrder(askQuestion: boolean) {
        if (askQuestion) {
            let ok = await this.notifyManagementService.openConfirmDialog('Attenzione', 'Sei sicuro di voler abbandonare la lavorazione dell\'ordine?');
            if (!ok)
                return;
        }

        this.settingsManagementService.removeSettings(this.CURRENT_RESOURCE_KEY);
        this.router.navigate(["home"]);
    }
}
