import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'app-resource-services-sheet',
    templateUrl: './resource-services-sheet.component.html',
    styleUrls: ['./resource-services-sheet.component.scss']
})
export class ResourceServicesSheetComponent implements OnInit {

    constructor() { }

    ngOnInit(): void {
    }

    onServiceClick() {

    }

}
