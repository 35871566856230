<div class="custom-dialog-wrapper">
    <h2 mat-dialog-title><b>{{ title }}</b></h2>
    <div class="custom-dialog-container">
        <div class="mat-dialog-content" mat-dialog-content>
            <p [innerHTML]="message" class="dialog-message"></p>
        </div>
        <div align="end" class="mat-dialog-actions justify-content-center" mat-dialog-actions style="padding-top: 20px">
            <button (click)="onConfirmClick()" cdkFocusInitial class="mat-focus-indicator mat-raised-button mat-button-base" mat-raised-button>OK</button>
        </div>
    </div>
</div>
