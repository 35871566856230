import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { CommonService } from 'projects/fe-common/src/lib/services/common.service';

@Component({
    selector: 'app-item-quantity-dialog',
    templateUrl: './item-quantity-dialog.component.html',
    styleUrls: ['./item-quantity-dialog.component.scss']
})
export class ItemQuantityDialogComponent implements OnInit {
    selectedItem: any;
    movedQuantity: number;
    totalQuantity: number;

    constructor(private commonService: CommonService,
        public translate: TranslateService,
        public dialogRef: MatDialogRef<ItemQuantityDialogComponent>,
        @Inject(MAT_DIALOG_DATA) data: any) {
        this.selectedItem = data;
        this.movedQuantity = this.commonService.valueOrDefault(this.selectedItem.quantityMovedInProduction, 0);
        this.totalQuantity = this.commonService.valueOrDefault(this.selectedItem.quantity, 0);
    }

    ngOnInit(): void {
    }

    getTitle() {
        return this.selectedItem ? 'Movimentazione: ' + this.selectedItem.codeSL: '';
    }

    getQuantityInfo() {
        return this.movedQuantity + '/' + this.totalQuantity;
    }

    onUpdateQuantity(isAdd) {
        if (isAdd) {
            this.movedQuantity = Math.min(++this.movedQuantity, this.totalQuantity);
        } else {
            this.movedQuantity = Math.max(--this.movedQuantity, 0);
        }
    }

    onCancelClick() {
        this.dialogRef.close(null);
    }

    onConfirmClick() {
        this.dialogRef.close({ item: this.selectedItem, movedQuantity: this.movedQuantity });
    }
}
