<div class="page-container">
    <app-main-toolbar class="main-toolbar"></app-main-toolbar>
    <div class="page-content-container">
        <div class="green-pass-container">
            <div class="greenpass-fail">
                <input id="upload-btn" type="file" #fileInput multiple (change)="fileBrowseHandler($event.target.files)">
                {{'UploadDocument'|translate}}
                <div class="upload-button-container">
                    <b>{{documentName}}</b>
                </div>
                <div class="upload-button-container">
                    <i class="material-icons qrcode-image">description</i>
                </div>
                <div class="action-container">
                    <label for="upload-btn" class="action-btn">
                        <i class="material-icons size-50">file_upload</i>
                    </label>
                </div>
                <div class="action-container" *ngIf="uploadedFile">
                    {{uploadedFile.name}}
                </div>
                <div class="control-item" *ngIf="expirationDateNeeded">
                    <mat-form-field>
                        <input matInput [matDatepicker]="picker" [min]="today" (dateChange)="dateChangeEvent($event)" placeholder="{{'ExpirationDate' | translate}}">
                        <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                        <mat-datepicker #picker></mat-datepicker>
                    </mat-form-field>
                </div>
                <button mat-flat-button color="primary" class="print-button round" (click)="onConfirm()">{{'Confirm'|translate}}</button>
            </div>
        </div>
    </div>
    <app-main-tab-bar class="main-tab-bar" [ShowBack]="true" (BackEvent)="onBack()"></app-main-tab-bar>
</div>