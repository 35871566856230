<div class="page-container">
    <app-main-toolbar class="main-toolbar"></app-main-toolbar>
    <div class="page-content-container">
        <div class="control-container">
            <div *ngIf="currentUIMode == UI_MODE.uiModeStart" class="main-action-container">
                <mat-nav-list class="action-list">
                    <ng-container>
                        <a mat-list-item class="action-item" [ngStyle]="getActionStyle()" (click)="onSelectMode(UI_MODE.uiModeSL)">
                            <div class="activate-button">
                                <mat-label class="item-text white-text" mat-line>Attiva/Gestisci Semilavorato</mat-label>
                            </div>
                            <i class="material-icons white-text button-icon">flag</i>
                        </a>

                        <a mat-list-item class="action-item" [ngStyle]="getActionStyle()" (click)="onSelectMode(UI_MODE.uiModeDeactivateSL)">
                            <div class="activate-button">
                                <mat-label class="item-text white-text" mat-line>Disattiva Semilavorato</mat-label>
                            </div>
                            <i class="material-icons white-text button-icon">highlight_off</i>
                        </a>

                        <a mat-list-item class="action-item" [ngStyle]="getActionStyle()" (click)="onSelectMode(UI_MODE.uiModePF)">
                            <div class="activate-button">
                                <mat-label class="item-text white-text" mat-line>Avvia Lavorazione Prodotto Finito</mat-label>
                            </div>
                            <i class="material-icons white-text button-icon">settings</i>
                        </a>

                        <a mat-list-item class="action-item" [ngStyle]="getActionStyle()" (click)="onSelectMode(UI_MODE.uiMoveIntoContainer)">
                            <div class="activate-button">
                                <mat-label class="item-text white-text" mat-line>Aggiungi S/L in un container</mat-label>
                            </div>
                            <i class="material-icons white-text button-icon">file_download</i>
                        </a>

                        <a mat-list-item class="action-item" [ngStyle]="getActionStyle()" (click)="onSelectMode(UI_MODE.uiMoveOutsideContainer)">
                            <div class="activate-button">
                                <mat-label class="item-text white-text" mat-line>Togli S/L dal container</mat-label>
                            </div>
                            <i class="material-icons white-text button-icon">upload</i>
                        </a>

                    </ng-container>
                </mat-nav-list>
            </div>

            <mat-tab-group *ngIf="currentUIMode == UI_MODE.uiModeSL" class="group-tab">
                <mat-tab>
                    <div class="main-action-container">
                        <mat-nav-list class="action-list">
                            <div *ngIf="currentActionState == ACTION_STATE.asOrderActive" class="order-summary-card">
                                <div class="main-container">
                                    <div class="header">
                                        Produzione Pianificata
                                    </div>
                                    <div class="bullet-container">
                                        <div class="indicator-container">
                                            <div class="bullet-text total-quantity" matTooltip="Quantity">
                                                {{ getPlanItemQuantity() }}</div>
                                            <div class="bullet-info-text">Quantità</div>
                                        </div>
                                        <div class="indicator-container">
                                            <div class="bullet-text total-quantity" matTooltip="Done">
                                                {{ getPlanItemActive() }}</div>
                                            <div class="bullet-info-text">Attivati</div>
                                        </div>
                                        <div class="indicator-container">
                                            <div class="bullet-text total-quantity" matTooltip="Done">
                                                {{ getPlanItemEligible() }}</div>
                                            <div class="bullet-info-text">Conformi</div>
                                        </div>
                                        <div class="indicator-container">
                                            <div class="bullet-text total-quantity" matTooltip="Scarp">
                                                {{ getPlanItemScrap() }}</div>
                                            <div class="bullet-info-text">Scrap</div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <ng-container *ngIf="currentActionState == ACTION_STATE.asStartOrder">
                                <a mat-list-item class="action-item" [ngStyle]="getActionStyle()" (click)="onStartResourceProduction()">
                                    <mat-label class="item-text white-text" mat-line>Lettura QR del forno</mat-label>
                                    <i class="material-icons white-text button-icon">beenhere</i>
                                </a>
                            </ng-container>

                            <ng-container *ngIf="currentActionState == ACTION_STATE.asOrderActive">
                                <a *ngFor="let cmd of productActivationCommands" mat-list-item class="action-item" [ngStyle]="getActionStyle()" (click)="onActivationCommand(cmd)">
                                    <div class="activate-button">
                                        <mat-label class="item-text white-text" mat-line>Attiva {{ cmd.productCode }}</mat-label>
                                        <span class="spacer"></span>
                                        <mat-label class="item-text white-text" mat-line>{{ cmd.done }}/{{ cmd.quantity }}</mat-label>
                                    </div>
                                    <i class="material-icons white-text button-icon">view_in_ar</i>
                                </a>

                                <a *ngIf="productActivationCommands.length == 0" mat-list-item class="action-item" [ngStyle]="getActionStyle()" (click)="onCloseOrder(false)">
                                    <mat-label class="item-text white-text" mat-line>Chiudi lavorazione</mat-label>
                                    <i class="material-icons white-text button-icon">thumb_up</i>
                                </a>

                                <div class="spacer"></div>

                                <a mat-list-item class="action-item" [ngStyle]="getActionStyle(true)" (click)="onCloseOrder(true)">
                                    <mat-label class="item-text white-text" mat-line>Abbandona lavorazione ({{ activeResource }})</mat-label>
                                    <i class="material-icons white-text">directions_run</i>
                                </a>
                            </ng-container>

                            <ng-container *ngIf="currentActionState == ACTION_STATE.asCloseOrder">
                                <a mat-list-item class="action-item" [ngStyle]="getActionStyle()" (click)="onCloseOrder(false)">
                                    <mat-label class="item-text white-text" mat-line>Close Order</mat-label>
                                    <i class="material-icons white-text button-icon">sports_score</i>
                                </a>
                            </ng-container>

                            <ng-container *ngIf="currentActionState == ACTION_STATE.asOrderDead">
                                <div class="spacer dead-message">{{ deadMessage }}</div>

                                <a mat-list-item class="action-item" [ngStyle]="getActionStyle(true)" (click)="onCloseOrder(true)">
                                    <mat-label class="item-text white-text" mat-line>Abbandona lavorazione ({{ activeResource }})</mat-label>
                                    <i class="material-icons white-text">directions_run</i>
                                </a>
                            </ng-container>

                        </mat-nav-list>
                    </div>
                </mat-tab>
                <mat-tab>
                    <div class="qr-scanner-container">
                        <fco-qr-scanner #scanner (scanSuccess)="onCodeResult($event)" [camera]="currentCamera">
                        </fco-qr-scanner>
                    </div>
                </mat-tab>
            </mat-tab-group>

            <mat-tab-group *ngIf="currentUIMode == UI_MODE.uiModeDeactivateSL" class="group-tab">
                <mat-tab>
                    <div class="qr-scanner-container">
                        <div class="qr-scanner-label color blue-back">
                            <div class="qr-scanner-text white-text" mat-line>Inquadra il QR del semilavorato da disattivare</div>
                        </div>
                        <fco-qr-scanner #scanner (scanSuccess)="onScanDeactivateSL($event)" [camera]="currentCamera">
                        </fco-qr-scanner>
                    </div>
                </mat-tab>
            </mat-tab-group>

            <mat-tab-group *ngIf="currentUIMode == UI_MODE.uiModePF" class="group-tab">
                <mat-tab>
                    <div class="main-action-container">
                        <mat-nav-list class="action-list">
                            <ng-container *ngIf="showProductionItems">
                                <a *ngFor="let item of operatorProductionItems" mat-list-item class="action-item" [ngStyle]="getActionStyle()" (click)="onShowProductionActivities(item)">
                                    <div class="activate-button">
                                        <mat-label class="item-text-product white-text" style="margin-right: 5px" mat-line>Avvia Produzione {{ item.codePF }}</mat-label>
                                        <span class="spacer"></span>
                                        <mat-label class="item-text-product white-text" style="width: 40px; margin-right: 5px" mat-line>{{ item.done }}/{{ item.quantity }}</mat-label>
                                    </div>
                                    <i class="material-icons white-text button-icon">view_in_ar</i>
                                </a>

                                <a *ngIf="operatorProductionItems.length == 0" mat-list-item class="action-item" [ngStyle]="getActionStyle()" (click)="onBack()">
                                    <mat-label class="item-text white-text" mat-line>Non hai una produzione assegnata</mat-label>
                                    <i class="material-icons white-text button-icon">thumb_up</i>
                                </a>

                                <!--
                                <div class="spacer"></div>
                                
                                <a mat-list-item class="action-item" [ngStyle]="getActionStyle(true)">
                                    <mat-label class="item-text white-text" mat-line>Abbandona lavorazione</mat-label>
                                    <i class="material-icons white-text">directions_run</i>
                                </a>
                                -->
                            </ng-container>

                            <ng-container *ngIf="!showProductionItems">
                                <div style="font-size: x-large; font-weight: bold; text-align: center;">
                                    Elenco delle attività per la produzione di ({{ currentFinalProductItem.codePF }}):
                                </div>

                                <a *ngFor="let act of productionActivities" mat-list-item class="action-item" [ngStyle]="getActivityStyle()">
                                    <div class="activate-button">
                                        <mat-label class="item-text-product white-text" style="margin-right: 5px" mat-line>{{ act.activityName }}</mat-label>
                                    </div>
                                    <i class="material-icons white-text button-icon">settings</i>
                                </a>

                                <div class="spacer"></div>

                                <a mat-list-item class="action-item" [ngStyle]="getActionStyle(false)" (click)="onStartProduct()">
                                    <mat-label class="item-text white-text" mat-line>Continua</mat-label>
                                    <i class="material-icons white-text button-icon">beenhere</i>
                                </a>

                                <a mat-list-item class="action-item" [ngStyle]="getActionStyle(true)" (click)="onBack()">
                                    <mat-label class="item-text white-text" mat-line>Indietro</mat-label>
                                    <i class="material-icons white-text">directions_run</i>
                                </a>

                            </ng-container>
                        </mat-nav-list>
                    </div>
                </mat-tab>
                <mat-tab>
                    <div class="qr-scanner-container">
                        <fco-qr-scanner #scanner (scanSuccess)="onCodeResult($event)" [camera]="currentCamera">
                        </fco-qr-scanner>
                    </div>
                </mat-tab>
            </mat-tab-group>

            <mat-tab-group *ngIf="(currentUIMode == UI_MODE.uiMoveIntoContainer) || (currentUIMode == UI_MODE.uiMoveOutsideContainer)" class="group-tab">
                <mat-tab>
                    <div class="qr-scanner-container">
                        <div class="qr-scanner-label color" [ngClass]="currentScanState ==  SCAN_STATE.ssContainer ? 'blue-back' : 'orange-back'">
                            <div class="qr-scanner-text white-text" *ngIf="currentScanState ==  SCAN_STATE.ssContainer" mat-line>Inquadra il QR del container</div>
                            <div class="qr-scanner-text white-text" *ngIf="currentScanState ==  SCAN_STATE.ssContainerSL" mat-line>Inquadra il QR del semilavorato</div>
                        </div>
                        <fco-qr-scanner #scanner (scanSuccess)="(currentScanState ==  SCAN_STATE.ssContainer) ? onScanContainer($event) : onScanSL($event)" [camera]="currentCamera">
                        </fco-qr-scanner>
                    </div>
                </mat-tab>
            </mat-tab-group>
        </div>
    </div>
    <app-main-tab-bar class="main-tab-bar" [ShowBack]="true" (BackEvent)="onBack()"></app-main-tab-bar>
</div>