<mat-nav-list class="service-action-sheet">
    <a mat-list-item class="action-item" (click)="onServiceClick()">
        <mat-label class="item-text white-text" mat-line>Taxi</mat-label>
        <i class="material-icons white-text">local_taxi</i>
    </a>

    <a mat-list-item class="action-item" (click)="onServiceClick()">
        <mat-label class="item-text white-text" mat-line>Catering</mat-label>
        <i class="material-icons white-text">local_cafe</i>
    </a>

    <a mat-list-item class="action-item" (click)="onServiceClick()">
        <mat-label class="item-text white-text" mat-line>Helpdesk</mat-label>
        <i class="material-icons white-text">support_agent</i>
    </a>
</mat-nav-list>