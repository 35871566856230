<div class="page-container">
    <app-main-toolbar class="main-toolbar"></app-main-toolbar>
    <div class="page-content-container">
        <div class="control-container">
            <div *ngIf="currentUIMode == UI_MODE.uiModeStart" class="main-action-container">
                <mat-nav-list class="action-list">
                    <ng-container>
                        <a mat-list-item class="action-item" [ngStyle]="getActionStyle()" (click)="onSelectMode(UI_MODE.uiInitPackage)">
                            <div class="activate-button">
                                <mat-label class="item-text white-text" mat-line>Attiva Imballaggio</mat-label>
                            </div>
                            <i class="material-icons white-text button-icon">inventory_2</i>
                        </a>

                        <a mat-list-item class="action-item" [ngStyle]="getActionStyle()" (click)="onSelectMode(UI_MODE.uiMoveIntoPackage)">
                            <div class="activate-button">
                                <mat-label class="item-text white-text" mat-line>Aggiungi prodotto nell'imballaggio</mat-label>
                            </div>
                            <i class="material-icons white-text button-icon">archive</i>
                        </a>

                        <a mat-list-item class="action-item" [ngStyle]="getActionStyle()" (click)="onSelectMode(UI_MODE.uiMoveOutsidePackage)">
                            <div class="activate-button">
                                <mat-label class="item-text white-text" mat-line>Rimuovi prodotto dall'imballaggio</mat-label>
                            </div>
                            <i class="material-icons white-text button-icon">unarchive</i>
                        </a>

                        <a mat-list-item class="action-item" [ngStyle]="getActionStyle()" (click)="onSelectMode(UI_MODE.uiInspectPackage)">
                            <div class="activate-button">
                                <mat-label class="item-text white-text" mat-line>Controlla imballaggio</mat-label>
                            </div>
                            <i class="material-icons white-text button-icon">search</i>
                        </a>

                    </ng-container>
                </mat-nav-list>
            </div>

            <div *ngIf="currentUIMode == UI_MODE.uiInitPackage" class="qr-scanner-container">
                <div class="qr-scanner-label color blue-back">
                    <div class="qr-scanner-text white-text">Inquadra il QR dell'imballaggio e seleziona la sua tipologia</div>
                    <mat-form-field class="area-selector">
                        <mat-select [(ngModel)]="packageTypeId">
                            <mat-option *ngFor="let pkg of packageTypes" [value]="pkg.id">
                                {{ pkg.name + ' - ' + pkg.description }}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>

                </div>
                <fco-qr-scanner #scanner (scanSuccess)="onScan($event)" [camera]="currentCamera">
                </fco-qr-scanner>
            </div>

            <div *ngIf="(currentUIMode == UI_MODE.uiMoveIntoPackage) || (currentUIMode == UI_MODE.uiMoveOutsidePackage)" class="qr-scanner-container">
                <div class="qr-scanner-label orange-back" *ngIf="currentScanState ==  SCAN_STATE.ssPackage">
                    <div class="qr-scanner-text white-text" mat-line>Inquadra il QR dell'imballaggio</div>
                </div>
                <div class="qr-scanner-label blue-back" *ngIf="currentScanState ==  SCAN_STATE.ssProduct">
                    <div class="qr-scanner-text white-text" mat-line>Inquadra il QR del prodotto</div>
                </div>
                <fco-qr-scanner #scanner (scanSuccess)="(currentScanState == SCAN_STATE.ssPackage) ? onScanPackage($event) : onScanProduct($event)" [camera]="currentCamera">
                </fco-qr-scanner>
            </div>

            <div *ngIf="currentUIMode == UI_MODE.uiInspectPackage" class="qr-scanner-container">
                <div class="qr-scanner-label color blue-back">
                    <div class="qr-scanner-text white-text">Inquadra il QR dell'imballaggio</div>
                </div>
                <fco-qr-scanner #scanner (scanSuccess)="onScan($event)" [camera]="currentCamera">
                </fco-qr-scanner>
            </div>

        </div>


    </div>
    <app-main-tab-bar class="main-tab-bar" [ShowBack]="true" (BackEvent)="onBack()"></app-main-tab-bar>
</div>