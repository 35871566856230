<mat-nav-list class="tracking-action-sheet">

    <ng-container *ngIf="currentMode == UIMode.Tracking">
        <ng-container *ngIf="currentProduct.status == ProductStatus.SCRAP">
            <a mat-list-item class="action-item" [ngStyle]="getActionStyle()" (click)="onScrapProduct()">
                <mat-label class="item-text white-text" mat-line>Scartato</mat-label>
                <i class="material-icons white-text">delete</i>
            </a>
        </ng-container>

        <ng-container *ngIf="currentProduct.status == ProductStatus.DRAFT">
            <a mat-list-item class="action-item" [ngStyle]="getActionStyle()" (click)="onProductChangeStatus(ProductStatus.ACTIVE)">
                <mat-label class="item-text white-text" mat-line>Activate</mat-label>
                <i class="material-icons white-text">play_arrow</i>
            </a>
        </ng-container>

        <ng-container *ngIf="currentProduct.status == ProductStatus.ACTIVE">
            <a mat-list-item class="action-item" [ngStyle]="getActionStyle()" (click)="onProductChangeStatus(ProductStatus.ELIGIBLE)">
                <mat-label class="item-text white-text" mat-line>Valido</mat-label>
                <i class="material-icons white-text">check_circle</i>
            </a>

            <a mat-list-item class="action-item" [ngStyle]="getActionStyle()" (click)="onProductChangeStatus(ProductStatus.SCRAP)">
                <mat-label class="item-text white-text" mat-line>Scrap</mat-label>
                <i class="material-icons white-text">delete</i>
            </a>
        </ng-container>

        <ng-container *ngIf="currentProduct.status == ProductStatus.ELIGIBLE">
            <a mat-list-item class="action-item" [ngStyle]="getActionStyle()" (click)="onProductChangeStatus(ProductStatus.START_MOVE_WORKING)">
                <mat-label class="item-text white-text" mat-line>Sposta in Lavorazione</mat-label>
                <i class="material-icons white-text">fast_forward</i>
            </a>
        </ng-container>

        <!-- WORKING PHASE -->
        <ng-container *ngIf="currentProduct.status == ProductStatus.START_MOVE_WORKING">
            <a mat-list-item class="action-item" [ngStyle]="getActionStyle()" (click)="onProductChangeStatus(ProductStatus.END_MOVE_WORKING)">
                <mat-label class="item-text white-text" mat-line>Arrivato in Lavorazione</mat-label>
                <i class="material-icons white-text">location_on</i>
            </a>
        </ng-container>

        <ng-container *ngIf="currentProduct.status == ProductStatus.END_MOVE_WORKING">
            <a mat-list-item class="action-item" [ngStyle]="getActionStyle()" (click)="onProductChangeStatus(ProductStatus.WORKING)">
                <mat-label class="item-text white-text" mat-line>Inizio Lavorazione</mat-label>
                <i class="material-icons white-text">engineering</i>
            </a>
        </ng-container>

        <ng-container *ngIf="currentProduct.status == ProductStatus.WORKING">
            <a mat-list-item class="action-item" [ngStyle]="getActionStyle()" (click)="onProductChangeStatus(ProductStatus.DONE_WORKING)">
                <mat-label class="item-text white-text" mat-line>Fine Lavorazione</mat-label>
                <i class="material-icons white-text">recommend</i>
            </a>
        </ng-container>

        <ng-container *ngIf="currentProduct.status == ProductStatus.DONE_WORKING">
            <a mat-list-item class="action-item" [ngStyle]="getActionStyle()" (click)="onProductChangeStatus(ProductStatus.START_MOVE_PACKAGING)">
                <mat-label class="item-text white-text" mat-line>Sposta in packaging</mat-label>
                <i class="material-icons white-text">fast_forward</i>
            </a>
        </ng-container>

        <!-- PACKAGING PHASE -->
        <ng-container *ngIf="currentProduct.status == ProductStatus.START_MOVE_PACKAGING">
            <a mat-list-item class="action-item" [ngStyle]="getActionStyle()" (click)="onProductChangeStatus(ProductStatus.END_MOVE_PACKAGING)">
                <mat-label class="item-text white-text" mat-line>Arrivato in packaging</mat-label>
                <i class="material-icons white-text">location_on</i>
            </a>
        </ng-container>

        <ng-container *ngIf="currentProduct.status == ProductStatus.END_MOVE_PACKAGING">
            <a mat-list-item class="action-item" [ngStyle]="getActionStyle()" (click)="onProductChangeStatus(ProductStatus.PACKAGING)">
                <mat-label class="item-text white-text" mat-line>Inizio packaging</mat-label>
                <i class="material-icons white-text">inventory_2</i>
            </a>
        </ng-container>

        <ng-container *ngIf="currentProduct.status == ProductStatus.PACKAGING">
            <a mat-list-item class="action-item" [ngStyle]="getActionStyle()" (click)="onProductChangeStatus(ProductStatus.DONE_PACKAGING)">
                <mat-label class="item-text white-text" mat-line>Fine packaging</mat-label>
                <i class="material-icons white-text">recommend</i>
            </a>
        </ng-container>

        <ng-container *ngIf="currentProduct.status == ProductStatus.DONE_PACKAGING">
            <a mat-list-item class="action-item" [ngStyle]="getActionStyle()" (click)="onProductChangeStatus(ProductStatus.LOGISTIC)">
                <mat-label class="item-text white-text" mat-line>Logistica</mat-label>
                <i class="material-icons white-text">shopping_cart_checkout</i>
            </a>
        </ng-container>

        <!------------------------------------->
        <ng-container *ngIf="currentProduct.status == ProductStatus.LOGISTIC">
            <a mat-list-item class="action-item" [ngStyle]="getActionStyle()" (click)="onProductChangeStatus(ProductStatus.SHIP)">
                <mat-label class="item-text white-text" mat-line>Distribuzione</mat-label>
                <i class="material-icons white-text">send</i>
            </a>
        </ng-container>

        <ng-container *ngIf="currentProduct.status == ProductStatus.SHIP">
            <a mat-list-item class="action-item" [ngStyle]="getActionStyle()" (click)="onProductChangeStatus(ProductStatus.SHIPPED)">
                <mat-label class="item-text white-text" mat-line>Spedito</mat-label>
                <i class="material-icons white-text">local_shipping</i>
            </a>
        </ng-container>
    </ng-container>

    <ng-container *ngIf="currentMode == UIMode.QA">
        <a mat-list-item class="action-item" [ngStyle]="getActionStyle()" (click)="onProductChangeStatus(ProductStatus.QUALITY_CHECK_OK)">
            <mat-label class="item-text white-text" mat-line>Controllo qualità OK</mat-label>
            <i class="material-icons white-text">check_circle</i>
        </a>

        <a mat-list-item class="action-item" [ngStyle]="getActionStyle()" (click)="onProductChangeStatus(ProductStatus.SCRAP)">
            <mat-label class="item-text white-text" mat-line>Scrap</mat-label>
            <i class="material-icons white-text">delete</i>
        </a>
    </ng-container>

    <ng-container *ngIf="currentMode == UIMode.Returned">

        <div *ngFor="let ol of currentProduct.operationLog" class="product-log-item">
            <div class="timestamp">{{ ol.timestamp | date: 'dd/MM/yyyy HH:mm:ss' }}</div>
            <div class="activity-text">{{ ol.user }} - {{ ol.status }}</div>
        </div>

        <a mat-list-item class="action-item" [ngStyle]="getActionStyle()" (click)="onProductChangeStatus(ProductStatus.RETURNED)">
            <mat-label class="item-text white-text" mat-line>Prodotto reso</mat-label>
            <i class="material-icons white-text">check_circle</i>
        </a>

    </ng-container>

    <ng-container *ngIf="currentMode == UIMode.SLActions">
        <div class="product-info-container">
            <div class="product-info-title">Codice (S/L): {{ currentProduct.productCode }}</div>
            <div class="product-info-title">Stato: <strong>{{ currentProduct.status }}</strong></div>
            <div *ngIf="showNote"><textarea [(ngModel)]="note" placeholder="Note" rows="5" style="width: 98%"></textarea></div>
            <div *ngIf="showDefect">
                <mat-form-field class="defect-selector">
                    <mat-label>Difetto</mat-label>
                    <mat-select [(ngModel)]="defect">
                        <mat-option *ngFor="let def of productDefects" [value]="def.name">
                            {{def.name}}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
        </div>

        <a *ngFor="let action of productActions" mat-list-item class="action-item" [ngStyle]="getActionStyle()" (click)="onSLProductAction(action)">
            <mat-label class="item-text white-text" mat-line>{{ action.caption }}</mat-label>
            <i class="material-icons white-text">{{ action.icon }}</i>
        </a>
    </ng-container>

    <ng-container *ngIf="currentMode == UIMode.Actions">
        <div *ngIf="currentProductWorkingData" class="product-info-container">
            <div class="product-info-title">Codice (P/F): {{ currentProductWorkingData.codePF }}</div>
            <div class="product-info-title">Stato: <strong>{{ currentProduct.currentStatus }}</strong></div>
            <div class="product-info-title">QR: <strong>{{ currentProduct.activationId }}</strong></div>
            <div *ngIf="showNote"><textarea [(ngModel)]="note" placeholder="Note" rows="5" style="width: 98%"></textarea></div>
            <div *ngIf="showDefect">
                <mat-form-field class="defect-selector">
                    <mat-label>Difetto</mat-label>
                    <mat-select [(ngModel)]="defect">
                        <mat-option *ngFor="let def of productDefects" [value]="def.name">
                            {{def.name}}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </div>

            <a *ngIf="showTechSpec" mat-list-item class="action-item">
                <mat-label class="item-text white-text" mat-line>Specifiche Tecniche</mat-label>
                <i class="material-icons white-text">
                    picture_as_pdf
                </i>
            </a>

            <a *ngIf="showAssemplySpec" mat-list-item class="action-item">
                <mat-label class="item-text white-text" mat-line>Specifiche Montaggio</mat-label>
                <i class="material-icons white-text">
                    picture_as_pdf
                </i>
            </a>
        </div>

        <div *ngIf="itemSL">
            <a *ngIf="itemSL.notActivated == false" mat-list-item class="info-item red-background">
                <mat-label class="info-item-text" mat-line>Codice (S/L): <strong>{{ itemSL.itemId }}</strong></mat-label>
                <mat-label class="info-item-text" mat-line>Operator: <strong>{{ itemSL.operator }}</strong></mat-label>
                <mat-label class="info-item-text" mat-line>Forno: <strong>{{ itemSL.resource }}</strong></mat-label>
                <mat-label class="info-item-text" mat-line>Data: <strong>{{ itemSL.timestamp }}</strong></mat-label>
                <mat-label class="info-item-text" mat-line>QR: <strong>{{ itemSL.activationId }}</strong></mat-label>
            </a>

            <a *ngIf="itemSL.notActivated == true" mat-list-item class="info-item orange-background">
                <mat-label class="info-item-text" style="text-wrap: wrap;" mat-line><strong>Il S/L di questo prodotto non è stato ancora attivato</strong></mat-label>
            </a>

        </div>

        <a *ngFor="let action of productActions" mat-list-item class="action-item" [ngStyle]="getActionStyle()" (click)="onProductAction(action)">
            <mat-label class="item-text white-text" mat-line>{{ action.caption }}</mat-label>
            <i class="material-icons white-text">{{ action.icon }}</i>
        </a>

        <a *ngFor="let info of productInfos" mat-list-item class="info-item">
            <mat-label class="info-item-text" mat-line>{{ info.timestamp }}</mat-label>
            <mat-label class="info-item-text" mat-line>{{ info.operator }}</mat-label>
            <mat-label class="info-item-text" mat-line>{{ info.action }}</mat-label>
        </a>
    </ng-container>

    <ng-container *ngIf="currentMode == UIMode.PackageInspect">
        <div class="product-info-container">
            <div class="product-info-title">Imballo: <div class="product-info-text">{{ packageInfo.name }}</div>
            </div>
            <div class="product-info-title">Descr: <div class="product-info-text">{{ packageInfo.description }}</div>
            </div>
            <div class="product-info-title">QR: <div class="product-info-text">{{ packageQR }}</div>
            </div>
        </div>

        <div *ngFor="let item of packageContents" class="product-info-container" style="margin-top: 5px; background-color: #eeeeee">
            <mat-label class="product-info-title">Codice:</mat-label>
            <mat-label class="product-info-text">{{ item.codePF }}</mat-label>

            <mat-label class="product-info-title">Descrizione:</mat-label>
            <mat-label class="product-info-text">{{ item.descriptionPF }}</mat-label>

            <mat-label class="product-info-title">QR:</mat-label>
            <mat-label class="product-info-text">{{ item.activationId }}</mat-label>

            <mat-label class="product-info-title">Operatore:</mat-label>
            <mat-label class="product-info-text">{{ item.operator }}</mat-label>

            <mat-label class="product-info-title">Timestamp:</mat-label>
            <mat-label class="product-info-text">{{ item.timestamp }}</mat-label>
        </div>
    </ng-container>


    <div class="spacer"></div>

    <a mat-list-item class="action-item" [ngStyle]="getActionStyle(true)" (click)="onCloseDrawer()">
        <mat-label class="item-text white-text" mat-line>Annulla</mat-label>
        <i class="material-icons white-text">directions_run</i>
    </a>
</mat-nav-list>