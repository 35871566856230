import { Component, OnInit, ViewChild } from '@angular/core';
import { MatBottomSheet } from '@angular/material/bottom-sheet';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { ProductsTrackingService } from 'projects/fe-common-v2/src/lib/services/products-tracking.service';
import { AdminSiteManagementService } from 'projects/fe-common/src/lib/services/admin-site-management.service';
import { CommonService } from 'projects/fe-common/src/lib/services/common.service';
import { NotifyManagementService } from 'projects/fe-common/src/lib/services/notify-management.service';
import { SettingsManagementService } from 'projects/fe-common/src/lib/services/settings-management.service';
import { UserManagementService } from 'projects/fe-common/src/lib/services/user-management.service';
import { ItemQuantityDialogComponent } from './item-quantity-dialog/item-quantity-dialog.component';
import { CameraType, QrScannerComponent } from 'projects/fe-common/src/lib/components/qr-scanner/qr-scanner.component';

enum UI_MODE {
    uiModeStart,
    uiModeContainer,
    uiModeProducts
}

@Component({
    selector: 'app-move-product',
    templateUrl: './move-product.component.html',
    styleUrls: ['./move-product.component.scss']
})
export class MoveProductComponent implements OnInit {
    @ViewChild('scanner') qrScanner: QrScannerComponent;

    scanning: boolean = false;
    currentCamera: CameraType = CameraType.Back;

    UI_MODE = UI_MODE;

    currentUIMode: UI_MODE;
    itemsToMoveInProduction: any[] = [];
    areas: any[] = [];
    destinationArea: any;

    constructor(private commonService: CommonService,
        private adminSiteManagementService: AdminSiteManagementService,
        private productsTrackingService: ProductsTrackingService,
        private userManagementService: UserManagementService,
        private bottomSheet: MatBottomSheet,
        private settingsManagementService: SettingsManagementService,
        private notifyManagementService: NotifyManagementService,
        private snackBar: MatSnackBar,
        private dialog: MatDialog,
        public translate: TranslateService,
        private router: Router) { }

    async ngOnInit() {
        this.currentUIMode = UI_MODE.uiModeStart;
    }

    async onSelectMode(mode) {
        if (mode == UI_MODE.uiModeProducts) {
            this.itemsToMoveInProduction = await this.getItemsToMoveInProduction();
        } else if (mode == UI_MODE.uiModeContainer) {
            this.areas = await this.getAreas();
            if (this.areas && (this.areas.length > 0))
                this.destinationArea = this.areas[0].name;
        }
        this.currentUIMode = mode;
    }

    async getAreas() {
        let res = await this.productsTrackingService.getShippingAreas(null);
        if (this.commonService.isValidResponse(res)) {
            return res.meta.data;
        } else {
            await this.notifyManagementService.openMessageDialog('ATTENZIONE', 'Si è verificato un errore: ' + res.reason);
            return [];
        }
    }

    async getItemsToMoveInProduction() {
        let res = await this.productsTrackingService.itemsToMoveInProduction();
        if (this.commonService.isValidResponse(res)) {
            return res.meta.data;
        } else {
            await this.notifyManagementService.openMessageDialog('ATTENZIONE', 'Si è verificato un errore: ' + res.reason);
            return [];
        }
    }

    async onItemMoveComplete(item) {
        console.log(item);
        let dialogRespose: ItemQuantityDialogComponent = await this.dialog.open(ItemQuantityDialogComponent, {
            width: '300px',
            panelClass: 'custom-dialog-container',
            data: item
        }).afterClosed().toPromise();
        if (dialogRespose) {
            let res = await this.productsTrackingService.itemsCommitMoveInProduction(dialogRespose);
            if (this.commonService.isValidResponse(res)) {
                this.onBack();
            } else {
                await this.notifyManagementService.openMessageDialog('ATTENZIONE', 'Si è verificato un errore: ' + res.reason);
            }
        }
    }

    async onScanContainer(resultString) {
        if ((resultString == null) || this.scanning)
            return;

        this.scanning = true;
        this.qrScanner.openUserMedia();

        // verificare il QR code del container
        let res = await this.productsTrackingService.validateContainerQR(resultString);
        if (this.commonService.isValidResponse(res)) {
            // aggiorno il container registrando l'area di movimento
            //
            let selectedContainer = res.data;
            selectedContainer.currentLocationArea = this.destinationArea;

            if (await this.productsTrackingService.createOrUpdateProductContainer(selectedContainer)) {
                await this.notifyManagementService.openMessageDialog('INFO', 'La movimentazione del container è stata registrata.');

                this.router.navigate(["home"]);
            }
        } else {
            await this.notifyManagementService.openMessageDialog('ATTENZIONE', 'Si è verificato un errore: ' + res.reason);
        }

        this.scanning = false;
    }

    getMovedText(item) {
        return this.commonService.valueOrDefault(item.quantityMovedInProduction, 0) + '/' + item.quantity;
    }

    async onBack() {
        this.router.navigate(["home"]);
    }
}
